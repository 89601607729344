import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import './style.css';

import LoadImage from './LoadImage';

import { useQuery, useSubscription } from '@apollo/client';

import { ACTIVE_IMAGE_POINTER } from '../../../apollo/queries/activeImagePointer';
import { ON_ACTIVE_IMAGE_POINTER_UPDATE } from '../../../apollo/subscriptions/onActiveImagePointerUpdate';

const PlayerView = (props) => {

  const { data: activeImagePointerData, error: activeImagePointerError, loading: activeImagePointerLoading } = useQuery(ACTIVE_IMAGE_POINTER);
  const { data: activeImagePointerSubData } = useSubscription(ON_ACTIVE_IMAGE_POINTER_UPDATE);

  if(activeImagePointerLoading) return (<p>Loading...</p>);
  if(activeImagePointerError) return (<p>Error...</p>);

  const activeImagePointer = activeImagePointerSubData ? activeImagePointerSubData.onActiveImagePointerUpdate : activeImagePointerData.activeImagePointer

  return (
    <Card style={{ padding: "0px", margin: "0px" }}>
      <CardContent style={{ padding: "0px", margin: "0px" }}>
        {activeImagePointer && activeImagePointer.src ?
        <LoadImage user={props.user} activeImagePointer={activeImagePointer && activeImagePointer} /> :
        "Warte auf Spielleiter..."}
      </CardContent>
    </Card>
  );
}

export default PlayerView;
