import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import './style.css';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { CURRENT_GAMESTATE } from '../../apollo/queries/currentGamestate';
import { UPDATE_GAMESTATE } from '../../apollo/mutations/updateGamestate';
import { ON_GAMESTATE_UPDATE } from '../../apollo/subscriptions/onGamestateUpdate';

const Marker = () => {

  const { data: currentGamestateData, error: currentGamestateError, loading: currentGamestateLoading } = useQuery(CURRENT_GAMESTATE);
  const { data: currentGamestateSubData } = useSubscription(ON_GAMESTATE_UPDATE);
  const [updateGamestate] = useMutation(UPDATE_GAMESTATE);

  if(currentGamestateLoading) return (<p>Loading...</p>);
  if(currentGamestateError) return (<p>Error...</p>);

  const currentGamestate = currentGamestateSubData ? currentGamestateSubData.onGamestateUpdate : currentGamestateData.currentGamestate;

  return (
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom>
        <b>Spielmodus</b>
      </Typography>
      <Card>
        <CardContent align="center">
          <ButtonGroup disableElevation variant="contained" color="primary">
            <Button disabled={currentGamestate === "JOINING"} onClick={() => updateGamestate({ variables: { gamestate: "JOINING" }})}>Spielstart</Button>
            <Button disabled={currentGamestate === "WAITING"} onClick={() => updateGamestate({ variables: { gamestate: "WAITING" }})}>Deaktivieren</Button>
            <Button disabled={currentGamestate === "BUZZER"} onClick={() => updateGamestate({ variables: { gamestate: "BUZZER" }})}>Buzzer</Button>
            <Button disabled={currentGamestate === "IMAGE_POINTER"} onClick={() => updateGamestate({ variables: { gamestate: "IMAGE_POINTER" }})}>Marker</Button>
          </ButtonGroup>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Marker;
