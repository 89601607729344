import React from 'react';

import Level from "./Level";
import Lives from "./Lives";
import Counter from "./Counter";

import './style.css';
import Game from "./Game";

const GDZOverlay = () => {

  let url = new URL(window.location.href)
  let params = new URLSearchParams(url.search);

  function getContent() {
    switch(params.get("content")) {
      case "level":
        return <Level/>;
      case "game":
        return <Game/>;
      case "lives":
        return <Lives/>;
      case "counter":
        return <Counter/>;
      default:
        return "NO CONTENT";
    }
  }

  return (
    getContent()
  );
}

export default GDZOverlay;
