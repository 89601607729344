import React, {useEffect} from 'react';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import './style.css';

import { useQuery, useSubscription } from '@apollo/client';

import { COUNTER } from '../../../apollo/queries/counter';
import { GAMELEVEL_SETTINGS } from '../../../apollo/queries/gameLevelSettings';
import { ON_COUNTER_CHANGED } from '../../../apollo/subscriptions/onCounterChanged';
import { ON_GAMELEVEL_SETTINGS_CHANGED } from '../../../apollo/subscriptions/onGameLevelSettingsChanged';

const Counter = () => {

  const { data: counterData, error: counterError, loading: counterLoading } = useQuery(COUNTER);
  const { data: counterSubData } = useSubscription(ON_COUNTER_CHANGED);
  const { data: settingsData, error: settingsError, loading: settingsLoading } = useQuery(GAMELEVEL_SETTINGS);
  const { data: settingsSubData } = useSubscription(ON_GAMELEVEL_SETTINGS_CHANGED);

  if(counterLoading || settingsLoading) return (<p>Loading...</p>);
  if(counterError || settingsError) return (<p>Error...</p>);

  const counter = counterSubData ? counterSubData.onCounterChanged : counterData.counter
  const settings = settingsSubData ? settingsSubData.onGameLevelSettingsChanged : settingsData.gameLevelSettings

  function renderCounterGrid() {
    var counterMap = [];
    for (var i = 1; i <= settings.counterMax; i++) {
      if(i>counter) {
        counterMap.push(
          <div
            className={"counter-container"}
            key={i}
          >
          </div>
        )
      } else {
        counterMap.push(
          <div
            className={i===counter ? "counter-container-elapsed fade" : "counter-container-elapsed"}
            key={i}
          >
          </div>
        )
      }
    }
    return counterMap;
  }

  return (
    <Container align="center">
      <Grid container spacing={5} alignContent="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12} style={{ padding: "0px" }}>
          <div className="counter-wrapper">
            {renderCounterGrid()}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Counter;
