import React from 'react';

import './style.css';

const NotFound = () => {
  return(
    <div>Ey, die Seite gibts nicht!!!</div>
  )
}

export default NotFound;
