import { gql } from '@apollo/client';

export const ALL_PLAYERS = gql`
  query {
    allPlayers {
      _id
      color
      name
      joinCode
      winnerRounds
      points
    }
  }
`;

export const ALL_PLAYERS_PLAYERVIEW = gql`
  query {
    allPlayers {
      _id
      color
      name
      winnerRounds
      points
    }
  }
`;
