import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import SDS_Logo from '../resources/SDS_Logo.png';

const FullpageLoader = (props) => {

  return (
    <Container>
      <Grid container spacing={3} alignContent="center" style={{ minHeight: '100vh' }}>
        <Grid align="center" item xs={12}>
          <img alt="SDS-Logo" src={SDS_Logo} width="200px"/>
        </Grid>
        <Grid align="center" item xs={12}>
          {props.error}
        </Grid>
      </Grid>
    </Container>
  );
}

export default FullpageLoader;
