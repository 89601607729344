import { gql } from '@apollo/client';

export const ON_GAMELEVEL_SETTINGS_CHANGED = gql`
  subscription {
    onGameLevelSettingsChanged {
      levels
      lives
      counterMax
      saveZones
      rechargeLiveZones
      levelNames
    }
  }
`;
