import { createMuiTheme } from '@material-ui/core/styles';

import './pandorTheme.css';

export default createMuiTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#0e0e0d'
    },
    primary: {
      main: '#f08026',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#d21f3c',
      contrastText: '#ffffff'
    }
  },
  neutral: {
    color: '#da7720'
  },
  overrides: {
    MuiPaper: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        color: '#afb6c5', // Some CSS
        backgroundColor: '#0d1b2f',
        borderRadius: '0px',
        border: '0px solid #25373e'
      },
      elevation1: {
        boxShadow: 'none'
      },
      elevation2: {
        boxShadow: 'none'
      },
      elevation3: {
        boxShadow: 'none'
      },
      elevation4: {
        boxShadow: 'none'
      }
    },
    MuiBadge: {
      badge: {
        position: 'relative',
        marginLeft: '5px'
      }
    },
    MuiMenu: {
      paper: {
        backgroundColor: "#0e0e0d",
        padding: "0px"
      },
      list: {
        padding: "0px"
      }
    },
    MuiCardContent: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        color: '#ffffff', // Some CSS
        backgroundColor: '#2f2f2b', // Some CSS
        borderRadius: '4px',
        padding: '12px',
        '&:last-child': {
          paddingBottom: '12px'
        }
      }
    },
    MuiMenuItem: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        color: '#ffffff', // Some CSS
        padding: '10px',
        '&:hover': {
          backgroundColor: '#f08026'
        },
        '&$selected': { // Name of the rule
          color: '#ffffff',
          backgroundColor: '#2f80ed',
          borderRadius: '0px 0px 3px 3px'
        }
      }
    },
    MuiPagination: {
      root: {
        borderRadius: '50px',
        padding: '5px 3px',
        backgroundColor: '#172840'
      }
    },
    MuiList: {
      root: {
        padding: '16px'
      }
    },
    MuiAccordion: {
      rounded: {
        borderRadius: '4px'
      },
      root: {
        marginTop: '2px',
        '&:before': {
          opacity: '0'
        }
      }
    },
    MuiListItemText: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        color: '#afb6c5'
      },
      primary: {
        color: '#ffffff'
      }
    },
    MuiButton: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        textTransform: 'none', // Some CSS
        color: '#afb6c5', // Some CSS
        borderRadius: '4px'
      },
      containedPrimary: {
        boxShadow: 'none',
        backgroundColor: '#f08026',
        '&$disabled': {
          background: '#484845',
          color: '#afb6c5'
        }
      },
      containedSecondary: {
        boxShadow: 'none',
        background: '#d21f3c',
        '&$disabled': {
          background: '#484845',
          color: '#afb6c5'
        }
      },
      outlinedPrimary: {
        borderWidth: '1px',
        '&:hover': {
          borderWidth: '1px'
        }
      },
      outlinedSecondary: {
        borderWidth: '1px',
        '&:hover': {
          borderWidth: '1px'
        }
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: '#172840',
        color: '#afb6c5',
        '&$disabled': {
          backgroundColor: '#484845',
          color: '#afb6c5'
        },
        '&:hover': {
          backgroundColor: '#1a2035'
        }
      }
    },
    MuiToggleButtonGroup: {
      root: {
        "&$selected": {
          backgroundColor: "transparent",
          boxShadow: "none"
        }
      }
    },
    MuiToggleButton: {
      label: {
        textTransform: 'none'
      },
      root: {
        '&$selected': {
          backgroundColor: '#2f80ed'
        }
      }
    },
    MuiFab: {
      root: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#1a2035'
        }
      },
      sizeSmall: {
        height: "36px",
        width: "36px"
      }
    },
    MuiTableCell: { // Name of the component ⚛️ / style sheet
      head: {
        color: '#afb6c5'
      },
      body: { // Name of the rule
        color: '#afb6c5', // Some CSS
        borderColor: 'transparent',
        padding: '16px'
      },
      root: {
        padding: '16px',
        borderBottom: '0px'
      }
    },
    MuiTableRow: {
      root: {
        '&:nth-of-type(even)': {
          backgroundColor: '#172840'
        }
      }
    },
    MuiInputAdornment: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        backgroundColor: '#0d1b2f' // Some CSS
      }
    },
    MuiTabs: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        backgroundColor: '#0d1b2f', // Some CSS
        borderRadius: '0px',
      }
    },
    MuiTab: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        border: '0px',
        textTransform: 'none',
        color: '#ffffff'
      },
      textColorPrimary: {
        '&$selected': {
          color: '#ffffff'
        },
      }
    },
    MuiChip: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        backgroundColor: '#172840' // Some CSS
      },
      colorPrimary: {
        backgroundColor: '#2f80ed'
      },
      colorSecondary: {
        background: '#ff0000'
      }
    },
    MuiAvatar: { // Name of the component ⚛️ / style sheet
      colorDefault: { // Name of the rule
        backgroundColor: '#1a2035', // Some CSS
        color: '#ffffff'
      }
    },
    MuiTypography: { // Name of the component ⚛️ / style sheet
      root: { // Name of the rule
        color: '#ffffff' // Some CSS
      },
    },
    MuiContainer: {
      root: {
        paddingTop: "12px",

      }
    },
    MuiTooltip: { // Name of the component ⚛️ / style sheet
      tooltip: { // Name of the rule
        color: '#ffffff', // Some CSS
      },
      popper: {
        opacity: '1'
      }
    },
    MuiSelect: {
      root: {
        color: 'rgba(255, 255, 255, 0.7)',
        fontSize: '0.875em',
        borderBottom: '0px'
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.7)'
      }
    },
    MuiExpansionPanelSummary: {
      root: {
        borderRadius: '4px',
        backgroundColor: '#172840',
        borderBottom: '3px solid #2f80ed'
      }
    },
    MuiExpansionPanel: {
      root: {
        '&:before': {
          display: 'none'
        }
      }
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: '#2f2f2b',
        height: '20px'
      }
    },
    MuiDialogContent: {
      root: {
        padding: '24px',
        whiteSpace: 'pre-wrap'
      }
    },
    MuiSlider: {
      markLabel: {
        filter: 'grayscale(100%)'
      },
      markLabelActive: {
        filter: 'grayscale(0%)'
      }
    },
  },
  typography: {
    useNextVariants: true
  }
});
