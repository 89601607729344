import React from 'react';

import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';

import './style.css';

const JoiningView = () => {

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          <b>Herzlich Willkommen zu Schlag den Streamer!</b>
        </Typography>
        <br/>
        Die Show beginnt in wenigen Minuten und du bist Live dabei. Diese Seite ist ein wichtiger Bestandteil. Lasse sie immer geöffnet und im Vordergrund. Pandor wird dir hier gleich alle Funktionen genauer erklären.
        <br/><br/>
        <b>Wir wünschen dir viel Spaß und drücken die Daumen!</b>
      </CardContent>
    </Card>
  );
}

export default JoiningView;
