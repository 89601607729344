import React, { useState } from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Attendees from './Attendees';
import Scoreboard from './Scoreboard';
import Buzzer from './Buzzer';
import Waiting from './Waiting';
import Joining from './Joining';
import Marker from './Marker';
import Mode from './Mode';
import MarkerPage from './MarkerPage';
import MarkerEvaluation from './MarkerEvaluation';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import SDS_Logo from '../../resources/SDS_Logo.png';

import './style.css';

import { useQuery, useSubscription } from '@apollo/client';

import { ACTIVE_IMAGE_POINTER } from '../../apollo/queries/activeImagePointer';
import { ON_ACTIVE_IMAGE_POINTER_UPDATE } from '../../apollo/subscriptions/onActiveImagePointerUpdate';
import { CURRENT_GAMESTATE } from '../../apollo/queries/currentGamestate';
import { ON_GAMESTATE_UPDATE } from '../../apollo/subscriptions/onGamestateUpdate';

const SDS = () => {

  const [ markerOpen, setMarkerOpen ] = useState(false);

  const { data: currentGamestateData, error: currentGamestateError, loading: currentGamestateLoading } = useQuery(CURRENT_GAMESTATE);
  const { data: activeImagePointerData, error: activeImagePointerError, loading: activeImagePointerLoading } = useQuery(ACTIVE_IMAGE_POINTER);
  const { data: activeImagePointerSubData } = useSubscription(ON_ACTIVE_IMAGE_POINTER_UPDATE);
  const { data: currentGamestateSubData } = useSubscription(ON_GAMESTATE_UPDATE);

  if(currentGamestateLoading || activeImagePointerLoading) return (<p>Loading...</p>);
  if(currentGamestateError || activeImagePointerError) return (<p>Error...</p>);

  const currentGamestate = currentGamestateSubData ? currentGamestateSubData.onGamestateUpdate : currentGamestateData.currentGamestate;
  const activeImagePointer = activeImagePointerSubData ? activeImagePointerSubData.onActiveImagePointerUpdate : activeImagePointerData.activeImagePointer;

  const handleToggleMarkerOpen = () => {
    setMarkerOpen(false)
  }

  return (
    <Container>
      {!markerOpen && !activeImagePointer &&
      <Grid container spacing={3} alignContent="center" style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Grid align="center" item xs={12}>
          <img alt="SDS-Logo" src={SDS_Logo} width="200px"/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Attendees/>
            </Grid>
            <Grid item xs={12}>
              <Scoreboard/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Mode/>
            {currentGamestate === "JOINING" && <Joining/>}
            {currentGamestate === "WAITING" && <Waiting/>}
            {currentGamestate === "BUZZER" && <Buzzer/>}
            {currentGamestate === "IMAGE_POINTER" && <Marker onToggleMarkerPage={() => setMarkerOpen(true)}/>}
          </Grid>
        </Grid>
      </Grid>}
      {markerOpen &&
      <Grid container spacing={3} alignContent="center" style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Grid item xs={12}>
          <Button startIcon={<ArrowBackIcon/>} onClick={() => setMarkerOpen(false)} color="primary" variant="contained">Zurück</Button>
        </Grid>
        <MarkerPage handleToggleMarkerOpen={handleToggleMarkerOpen}/>
      </Grid>}
      {activeImagePointer &&
      <Grid container spacing={3} alignContent="center" style={{ minHeight: 'calc(100vh - 64px)' }}>
        <MarkerEvaluation />
      </Grid>}
    </Container>
  );
}

export default SDS;
