import { gql } from '@apollo/client';

export const GET_ALL_IMAGE_POINTERS = gql`
  query {
    getAllImagePointers {
      _id
      headline
      src
      x
      y
    }
  }
`;
