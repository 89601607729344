import React from 'react';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import Cross from '../../../resources/cross.png';
import Heart from '../../../resources/heart.svg';
import Heart_Broken from '../../../resources/heart_broken.svg';
import Joker from '../../../resources/joker.svg';
import Joker_Used from '../../../resources/joker_used.svg';

import './style.css';

import { useQuery, useSubscription } from '@apollo/client';

import { CURRENT_LIVES } from '../../../apollo/queries/currentLives';
import { GAMELEVEL_SETTINGS } from '../../../apollo/queries/gameLevelSettings';
import { ON_CURRENT_LIVES_CHANGED } from '../../../apollo/subscriptions/onCurrentLivesChanged';
import { ON_GAMELEVEL_SETTINGS_CHANGED } from '../../../apollo/subscriptions/onGameLevelSettingsChanged';
import { JOKER_ENABLED } from '../../../apollo/queries/jokerEnabled';
import { ON_JOKER_ENABLED_CHANGED } from '../../../apollo/subscriptions/onJokerEnabledChanged';

const Lives = () => {

  const { data: currentLivesData, error: currentLivesError, loading: currentLivesLoading } = useQuery(CURRENT_LIVES);
  const { data: currentLivesSubData } = useSubscription(ON_CURRENT_LIVES_CHANGED);
  const { data: settingsData, error: settingsError, loading: settingsLoading } = useQuery(GAMELEVEL_SETTINGS);
  const { data: settingsSubData } = useSubscription(ON_GAMELEVEL_SETTINGS_CHANGED);
  const { data: jokerEnabledData, error: jokerEnabledError, loading: jokerEnabledLoading } = useQuery(JOKER_ENABLED);
  const { data: jokerEnabledSubData } = useSubscription(ON_JOKER_ENABLED_CHANGED);

  if(currentLivesLoading || settingsLoading || jokerEnabledLoading) return (<p>Loading...</p>);
  if(currentLivesError || settingsError || jokerEnabledError) return (<p>Error...</p>);

  const lives = currentLivesSubData ? currentLivesSubData.onCurrentLivesChanged : currentLivesData.currentLives
  const joker = jokerEnabledSubData ? jokerEnabledSubData.onJokerEnabledChanged : jokerEnabledData.jokerEnabled
  const settings = settingsSubData ? settingsSubData.onGameLevelSettingsChanged : settingsData.gameLevelSettings

  function renderLivesGrid() {
    var livesMap = [];
    for (var i = 1; i <= settings.lives; i++) {
      if(i>(settings.lives - lives)) {
        livesMap.push(
          <div
            className={"lives-container"}
            key={i}
          >
            <img alt="GDZ Heart" className="heart" src={Heart}/>
          </div>
        )
      } else {
        livesMap.push(
          <div
            className={i===(settings.lives - lives) ? "lives-container-lost shake" : "lives-container-lost"}
            key={i}
          >
            <img alt="GDZ Heart" className="heart" src={Heart_Broken}/>
            <img alt="GDZ Cross" className={i===(settings.lives - lives) ? "cross fade" : "cross"} src={Cross}/>
          </div>
        )
      }
    }
    return livesMap.reverse();
  }

  function renderJoker() {
    return (
      <div
        className={!joker ? "joker-container-used shake" : "joker-container"}
      >
        {!joker ? <img alt="GDZ Joker" className="joker" src={Joker_Used}/> : <img alt="GDZ Joker" className="joker" src={Joker}/>}
        {!joker && <img alt="GDZ Cross" className={"cross fade"} src={Cross}/>}
      </div>
    )
  }

  return (
    <Container align="center">
      <Grid container spacing={5} alignContent="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12} style={{ padding: "0px" }}>
          <div className="lives-wrapper">
            {renderLivesGrid()}
            {renderJoker()}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Lives;
