import React from 'react';

import { useSubscription } from '@apollo/client';

import { ON_BUZZER_ENABLED } from '../../../apollo/subscriptions/onBuzzerEnabled';
import { ON_BUZZ } from '../../../apollo/subscriptions/onBuzz';

import './style.css';

const CamBorder = (props) => {

  const { data: buzzSubData } = useSubscription(ON_BUZZ);
  const { data: buzzerEnabledSubData } = useSubscription(ON_BUZZER_ENABLED);

  return (
    <div className="camBorder" style={{ borderRadius: "50px", backgroundColor: buzzSubData && !buzzerEnabledSubData.onBuzzerEnabled && buzzSubData.onBuzz._id === props.attendee._id && "#ffffff" }}/>
  );
}

export default CamBorder;
