import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useSubscription, useQuery } from '@apollo/client';

import { ALL_PLAYERS } from '../../../apollo/queries/allPlayers';
import { ON_PLAYERS_CHANGED } from '../../../apollo/subscriptions/onPlayersChanged';
import { CURRENT_ROUND } from '../../../apollo/queries/currentRound';
import { ON_ROUND_CHANGED } from '../../../apollo/subscriptions/onRoundChanged';

import './style.css';

const Scoreboard = () => {

  const { data: allPlayersData, error: allPlayersError, loading: allPlayersLoading } = useQuery(ALL_PLAYERS);
  const { data: allPlayersSubData } = useSubscription(ON_PLAYERS_CHANGED);
  const { data: currentRoundData, error: currentRoundError, loading: currentRoundLoading } = useQuery(CURRENT_ROUND);
  const { data: currentRoundSubData } = useSubscription(ON_ROUND_CHANGED);

  if(allPlayersLoading || currentRoundLoading) return (<p>Loading...</p>);
  if(allPlayersError || currentRoundError) return (<p>Error...</p>);

  const allPlayers = allPlayersSubData ? allPlayersSubData.onPlayersChanged : allPlayersData.allPlayers
  const currentRound = currentRoundSubData ? currentRoundSubData.onRoundChanged : currentRoundData.currentRound
  const previousRound = currentRound-1

  function renderPointsGrid(player) {
    var points = [];
    for (var i = 1; i <= 10; i++) {
      const userData = allPlayers.find(e => e._id === player._id && e.winnerRounds.find(d => d === i))
      if(userData) {
        points.push(
          <div
            key={i}
            style={{ backgroundColor: userData.color }}
            className={previousRound === i ? "box highlight-slow" : "box"}
          >
            <b>{i}</b>
          </div>
        );
      } else {
        points.push(
          <div
            key={i}
            className="box"
          >
            <b>{i}</b>
          </div>
        );
      }
    }
    return points;
  }

  function renderRoundsGrid() {
    var rounds = [];
    for (var i = 1; i <= 10; i++) {
      const userData = allPlayers.find(e => e.winnerRounds.find(d => d === i))
      if(userData) {
        rounds.push(
          <div
            key={i}
            style={{ backgroundColor: userData.color, boxShadow: currentRound === i ? "0px 0px 5px 3px white" : "none" }}
            className={previousRound === i ? "box highlight" : "box"}
          >
            <b>{i}</b>
          </div>
        );
      } else {
        rounds.push(
          <div
            key={i}
            style={{ boxShadow: currentRound === i ? "0px 0px 5px 3px white" : "none" }}
            className={previousRound === i ? "box highlight" : "box"}
          >
            <b>{i}</b>
          </div>
        );
      }
    }
    return rounds;
  }

  const playerScores = allPlayers.map((player, index) =>
    <Grid item xs={6}>
      <div style={{ marginLeft: index === 0 ? "auto" : "none", marginRight: index === 1 ? "auto" : "none" }} class="scoreboard-wrapper">
        <Typography variant="h5" gutterBottom>
          <b>{player.name}</b>
        </Typography>
        <Card style={{ boxShadow: player.points >= 28 ? "0px 0px 5px 3px white" : "none" }}>
          <CardContent style={{ padding: "0px", width: "290px", height: "158px", position: "relative", backgroundColor: "#383838" }}>
            <div className="pixelmatrix"></div>
            <div className="points">
              <Typography className="points-value" style={{ marginBottom: "0px", textAlign: "center", fontSize: "150px" }} variant="h1" component="h2" gutterBottom>
                {player.points}
              </Typography>
            </div>
          </CardContent>
        </Card>
        <div style={{ marginTop: "10px" }} class="pointstowin-wrapper">
          <LinearProgress style={{ borderRadius: "4px" }} variant="determinate" value={player.points <= 28 ? (100/28)*player.points : 100}/>
        </div>
        <div style={{ marginTop: "10px" }} class="points-wrapper">
          {renderPointsGrid(player)}
        </div>
      </div>
    </Grid>
  )

  return (
    <Container>
      <Grid container spacing={5} alignContent="center" style={{ minHeight: '100vh' }}>
        <Grid item xs={12} style={{ padding: "0px" }}>
          <div className="rounds-wrapper">
            {renderRoundsGrid()}
          </div>
        </Grid>
        {playerScores}
      </Grid>
    </Container>
  );
}

export default Scoreboard;
