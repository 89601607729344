import React, { useEffect } from 'react';

import Container from '@material-ui/core/Container';

import { pipe } from 'image-pointer';

import { useSubscription, useQuery } from '@apollo/client';

import { ACTIVE_IMAGE_POINTER } from '../../../apollo/queries/activeImagePointer';
import { ON_ACTIVE_IMAGE_POINTER_UPDATE } from '../../../apollo/subscriptions/onActiveImagePointerUpdate';
import { USER_POINTERS } from '../../../apollo/queries/userPointers';
import { ON_POINTER_UPDATE } from '../../../apollo/subscriptions/onPointerUpdate';

import { IS_FROZEN } from '../../../apollo/queries/isFrozen';
import { IS_TARGET_PUBLIC } from '../../../apollo/queries/isTargetPublic';
import { ON_FREEZE_UPDATED } from '../../../apollo/subscriptions/onFreezeUpdated';
import { ON_TARGET_PUBLIC_UPDATED } from '../../../apollo/subscriptions/onTargetPublicUpdated';

import './style.css';

const distance = (x1, y1, x2, y2) => Math.sqrt( Math.pow((x1-x2), 2) + Math.pow((y1-y2), 2) );

let targetPosition = {x: 0, y: 0};
let positions = [];
const setPosition = (marker, position) => {
  let element = positions.find(x => x.marker === marker);
  if (!element) {
    element = {marker,position};
    positions.push(element);
  } else Object.assign(element, {position});
  let winner, winnerDistance;
  for (const pos of positions) {
    let d = distance(targetPosition.x, targetPosition.y, pos.position.x, pos.position.y);
    if(!winnerDistance || d < winnerDistance) {
      winnerDistance = d;
      winner = pos;
    }
  }
  for(const pos of positions)
    pos.marker.setWinner(pos === winner);
}

const winnerColor = '#2bab01';

const createMarker = (name, color) => {
  const marker = document.createElement('span');
  const text = document.createElement('div');
  text.innerText = name;
  const dot = document.createElement('div');
  dot.style.backgroundColor = color;
  marker.append(text, dot);
  return Object.assign(marker, {setWinner: winner => text.style.backgroundColor = winner ? winnerColor : 'black'});
}

let userPointerCache = {};
const getUserPointer = (user) => {
  if (userPointerCache[user._id]) return userPointerCache[user._id];
  const marker = createMarker(user.name, user.color);
  return userPointerCache[user._id] = Object.assign(pipe('target', {customMarker: marker}), {marker});
}

const disposeAllPointers = () => {
  for (const key of Object.keys(userPointerCache))
    userPointerCache[key].dispose()
  userPointerCache = {};
  positions = [];
}

const MarkerEvaluationImage = (props) => {

  useEffect(() => () => disposeAllPointers(), [])

  return (
    <img width="100%" height="100%" src={props.activeImagePointer ? props.activeImagePointer.src : ""} alt={props.activeImagePointer ? props.activeImagePointer.headline : ""} id="target"/>
  )
}

const Marker = () => {

  const { data: activeImagePointerData, error: activeImagePointerError, loading: activeImagePointerLoading } = useQuery(ACTIVE_IMAGE_POINTER);
  const { data: activeImagePointerSubData } = useSubscription(ON_ACTIVE_IMAGE_POINTER_UPDATE, {onSubscriptionData: raw => {
    const data = raw.subscriptionData.data.onActiveImagePointerUpdate;
    if(data && data.x && data.y) {
      const x = data.x*1000;
      const y = data.y*1000;
      let targetPointer = {
        _id: "targetMarker",
        name: "Zielmarker",
        color: "#000000"
      }
      const pointer = getUserPointer(targetPointer);
      pointer(x, y, true);
      targetPosition = {x,y};
    }
  }});
  const { data: userPointerData, error: userPointerError, loading: userPointerLoading } = useQuery(USER_POINTERS);

  const { data: isFrozenData, error: isFrozenError, loading: isFrozenLoading } = useQuery(IS_FROZEN);
  const { data: isFrozenSubData } = useSubscription(ON_FREEZE_UPDATED);
  const { data: isTargetPublicData, error: isTargetPublicError, loading: isTargetPublicLoading } = useQuery(IS_TARGET_PUBLIC);
  const { data: isTargetPublicSubData } = useSubscription(ON_TARGET_PUBLIC_UPDATED);

  const { data: userPointerSubData } = useSubscription(ON_POINTER_UPDATE, {onSubscriptionData: raw => {
    const data = raw.subscriptionData.data.onPointerUpdate;
    const pointer = getUserPointer(data.user);
    const {x, y, active} = data.pointer;
    pointer(x, y, active);
    setPosition(pointer.marker, {x, y})
  }});

  if(userPointerLoading || activeImagePointerLoading || isFrozenLoading || isTargetPublicLoading) return (<p>Loading...</p>);
  if(userPointerError || activeImagePointerError || isFrozenError || isTargetPublicError) return (<p>Error...</p>);

  const activeImagePointer = activeImagePointerSubData ? activeImagePointerSubData.onActiveImagePointerUpdate : activeImagePointerData.activeImagePointer
  const userPointer = userPointerSubData ? userPointerSubData.onPointerUpdate : userPointerData.userPointers
  const isFrozen = isFrozenSubData ? isFrozenSubData.onFreezeUpdated : isFrozenData.isFrozen;
  const isTargetPublic = isTargetPublicSubData ? isTargetPublicSubData.onTargetPublicUpdated : isTargetPublicData.isTargetPublic;

  if(!isTargetPublic){
    var divsToHide = document.getElementsByClassName("image-pointer-marker");
    for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.visibility = "hidden";
    }
  } else {
    var divsToShow = document.getElementsByClassName("image-pointer-marker");
    for(var i = 0; i < divsToShow.length; i++){
      divsToShow[i].style.visibility = null;
    }
  }

  return (
    <Container style={{ width: "1920px", height: "1080px", maxWidth: "1920px", padding: "0px", margin: "0px" }}>
      <MarkerEvaluationImage isTargetPublic={isTargetPublic} activeImagePointer={activeImagePointer && activeImagePointer} />
    </Container>
  );
}

export default Marker;
