import { gql } from '@apollo/client';

export const ON_POINTER_UPDATE = gql`
  subscription {
    onPointerUpdate {
      user {
        _id
        color
        name
        joinCode
        winnerRounds
        points
      }
      pointer {
        x
        y
        active
      }
    }
  }
`;

export const ON_POINTER_UPDATE_PLAYERVIEW = gql`
  subscription {
    onPointerUpdate {
      user {
        _id
        color
        name
        winnerRounds
        points
      }
      pointer {
        x
        y
        active
      }
    }
  }
`;
