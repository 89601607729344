import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Switch from '@material-ui/core/Switch';

import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import VolumeUp from '@material-ui/icons/VolumeUp';

import './style.css';

import buzzSound from '../../resources/buzzsound.mp3';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { BUZZER_ENABLED } from '../../apollo/queries/buzzerEnabled';
import { ENABLE_BUZZER } from '../../apollo/mutations/enableBuzzer';
import { ON_BUZZER_ENABLED } from '../../apollo/subscriptions/onBuzzerEnabled';
import { BUZZER_SETTINGS_QUERY } from '../../apollo/queries/buzzerSettings';
import { BUZZER_SETTINGS } from '../../apollo/mutations/buzzerSettings';
import { ON_BUZZ } from '../../apollo/subscriptions/onBuzz';

const Buzzer = () => {

  const audio = new Audio(buzzSound);

  const localVolume = localStorage.getItem('localVolume')
  const [volume, setVolume] = React.useState(localVolume ? localVolume : 0.3);

  const { data: buzzerEnabledData, error: buzzerEnabledError, loading: buzzerEnabledLoading } = useQuery(BUZZER_ENABLED);
  const { data: buzzerSettingsData, error: buzzerSettingsError, loading: buzzerSettingsLoading, refetch: buzzerSettingsRefetch } = useQuery(BUZZER_SETTINGS_QUERY);
  const { data: buzzerEnabledSubData } = useSubscription(ON_BUZZER_ENABLED);
  useSubscription(ON_BUZZ, {
    onSubscriptionData: raw => {
      audio.volume = volume;
      audio.play();
    }
  });
  const [enableBuzzer] = useMutation(ENABLE_BUZZER);
  const [setBuzzerSettings] = useMutation(BUZZER_SETTINGS, {
    onCompleted: data => {
      buzzerSettingsRefetch()
    }
  });

  if(buzzerEnabledLoading || buzzerSettingsLoading) return (<p>Loading...</p>);
  if(buzzerEnabledError || buzzerSettingsError) return (<p>Error...</p>);

  const buzzerEnabled = buzzerEnabledSubData ? buzzerEnabledSubData.onBuzzerEnabled : buzzerEnabledData.buzzerEnabled
  const buzzerSettings = buzzerSettingsData.buzzerSettings;

  const handleChangeVolume = (event, newValue) => {
    setVolume(newValue);
    localStorage.setItem('localVolume', newValue)
  };

  const handleSetBuzzerSettings = (reEnable, reEnableMs) => {
    setBuzzerSettings({
      variables: {
        settings: {
          reEnable: reEnable,
          reEnableMs: parseInt(reEnableMs)
        }
      }
    })
  }

  const marks = [
  {
    value: 1,
    label: '1s',
  },
  {
    value: 15,
    label: '15s',
  },
];

  return (
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom>
        <b>Buzzer</b>
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <List style={{ padding: '0px' }}>
                <ListItem>
                  <ListItemText primary={"Buzzer " + (buzzerEnabled ? "aktiviert" : "deaktiviert")}/>
                  <ListItemSecondaryAction style={{ minWidth: '200px' }}>
                    {buzzerEnabled ?
                    <Button onClick={() => enableBuzzer({ variables: { enable: false }})} fullWidth color="secondary" variant="contained">Buzzer deaktivieren</Button>
                    :
                    <Button onClick={() => enableBuzzer({ variables: { enable: true }})} fullWidth color="primary" variant="contained">Buzzer aktivieren</Button>}
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemText primary="Automatisch reaktivieren"/>
                  <ListItemSecondaryAction align="right" style={{ minWidth: '200px' }}>
                    <Switch
                      checked={buzzerSettings.reEnable}
                      onClick={(event) => handleSetBuzzerSettings(event.target.checked, buzzerSettings.reEnableMs)}
                      color="primary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemText primary="Reaktivieren nach"/>
                  <ListItemSecondaryAction style={{ minWidth: '200px' }}>
                    <Slider
                       defaultValue={buzzerSettings.reEnableMs/1000}
                       onChange={(event, newValue) => handleSetBuzzerSettings(buzzerSettings.reEnable, newValue*1000)}
                       step={1}
                       marks={marks}
                       min={1}
                       max={15}
                       valueLabelDisplay="auto"
                     />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider/>
                <ListItem>
                  <ListItemText primary="Lautstärke" />
                  <ListItemSecondaryAction style={{ minWidth: '200px' }}>
                    <Grid style={{ marginTop: '5px' }} container spacing={2}>
                      <Grid item xs>
                        <Slider
                          style={{ marginTop: '2px' }}
                          step={0.1}
                          min={0.0}
                          max={1.0}
                          value={volume}
                          onChange={handleChangeVolume}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton
                          onClick={() => {
                            audio.volume = volume;
                            audio.play()
                          }}
                          size="small"
                        >
                          <VolumeUp />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Buzzer;
