import { gql } from '@apollo/client';

export const BUZZER_SETTINGS = gql`
  mutation buzzerSettings($settings: BuzzerSettingsInput!) {
    buzzerSettings(settings: $settings) {
      reEnable
      reEnableMs
    }
  }
`;
