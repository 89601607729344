import { gql } from '@apollo/client';

export const SET_ROUND_WINNER = gql`
  mutation setRoundWinner($_id: String, $round: Int!) {
    setRoundWinner(_id: $_id, round: $round) {
      _id
      color
      name
      joinCode
      winnerRounds
      points
    }
  }
`;
