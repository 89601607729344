import { gql } from '@apollo/client';

export const UPDATE_ACTIVE_IMAGE_POINTER = gql`
  mutation updateActiveImagePointer($_id: String) {
    updateActiveImagePointer(_id: $_id) {
      _id
      headline
      src
      x 
      y
    }
  }
`;
