import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import './style.css';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { CURRENT_LIVES } from '../../apollo/queries/currentLives';
import { GAMELEVEL_SETTINGS } from '../../apollo/queries/gameLevelSettings';
import { SET_CURRENT_LIVES } from '../../apollo/mutations/setCurrentLives';
import { SET_GAMELEVEL_SETTINGS } from '../../apollo/mutations/setGameLevelSettings';
import { ON_CURRENT_LIVES_CHANGED } from '../../apollo/subscriptions/onCurrentLivesChanged';
import { ON_GAMELEVEL_SETTINGS_CHANGED } from '../../apollo/subscriptions/onGameLevelSettingsChanged';
import { JOKER_ENABLED } from '../../apollo/queries/jokerEnabled';
import { ON_JOKER_ENABLED_CHANGED } from '../../apollo/subscriptions/onJokerEnabledChanged';

const Lives = () => {

  const { data: currentLivesData, error: currentLivesError, loading: currentLivesLoading } = useQuery(CURRENT_LIVES);
  const { data: currentLivesSubData } = useSubscription(ON_CURRENT_LIVES_CHANGED);
  const { data: settingsData, error: settingsError, loading: settingsLoading } = useQuery(GAMELEVEL_SETTINGS);
  const { data: settingsSubData } = useSubscription(ON_GAMELEVEL_SETTINGS_CHANGED);
  const { data: jokerEnabledData, error: jokerEnabledError, loading: jokerEnabledLoading } = useQuery(JOKER_ENABLED);
  const { data: jokerEnabledSubData } = useSubscription(ON_JOKER_ENABLED_CHANGED);

  if(currentLivesLoading || settingsLoading || jokerEnabledLoading) return (<p>Loading...</p>);
  if(currentLivesError || settingsError || jokerEnabledError) return (<p>Error...</p>);

  const lives = currentLivesSubData ? currentLivesSubData.onCurrentLivesChanged : currentLivesData.currentLives
  const joker = jokerEnabledSubData ? jokerEnabledSubData.onJokerEnabledChanged : jokerEnabledData.jokerEnabled
  const settings = settingsSubData ? settingsSubData.onGameLevelSettingsChanged : settingsData.gameLevelSettings

  return (
    <Grid item xs={12}>

      <Card>
        <CardContent align="center">
          <Typography variant="h4">
            <b>{lives} von {settings.lives} Leben</b>
          </Typography>
          <Typography variant="h6">
            <b>{joker ? "+1 Joker" : <strike>+1 Joker</strike>}</b>
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Lives;
