import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import './style.css';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { GET_ALL_IMAGE_POINTERS } from '../../apollo/queries/getAllImagePointers';
import { DELETE_IMAGE_POINTER } from '../../apollo/mutations/deleteImagePointer';
import { ON_IMAGE_POINTERS_UPDATED } from '../../apollo/subscriptions/onImagePointersUpdated';
import { UPDATE_ACTIVE_IMAGE_POINTER } from '../../apollo/mutations/updateActiveImagePointer';

import { ACTIVE_IMAGE_POINTER } from '../../apollo/queries/activeImagePointer';
import { ON_ACTIVE_IMAGE_POINTER_UPDATE } from '../../apollo/subscriptions/onActiveImagePointerUpdate';

const Marker = (props) => {

  const { data: allImagePointersData, error: allImagePointersError, loading: allImagePointersLoading } = useQuery(GET_ALL_IMAGE_POINTERS);
  const { data: allImagePointersSubData } = useSubscription(ON_IMAGE_POINTERS_UPDATED);
  const { data: activeImagePointerData, error: activeImagePointerError, loading: activeImagePointerLoading } = useQuery(ACTIVE_IMAGE_POINTER);
  const { data: activeImagePointerSubData } = useSubscription(ON_ACTIVE_IMAGE_POINTER_UPDATE);
  const [deleteImagePointer] = useMutation(DELETE_IMAGE_POINTER);
  const [updateActiveImagePointer] = useMutation(UPDATE_ACTIVE_IMAGE_POINTER);

  if(allImagePointersLoading || activeImagePointerLoading) return (<p>Loading...</p>);
  if(allImagePointersError || activeImagePointerError) return (<p>Error...</p>);

  const allImagePointers = allImagePointersSubData ? allImagePointersSubData.onImagePointersUpdated : allImagePointersData.getAllImagePointers
  const _activeImagePointer = activeImagePointerSubData ? activeImagePointerSubData.onActiveImagePointerUpdate : activeImagePointerData.activeImagePointer

  const activateImagePointer = (savedMarker) => {
    updateActiveImagePointer({
      variables: {
        _id: savedMarker._id
      }
    })
  }

  const listMarker = allImagePointers.map((savedMarker) =>
    <Card style={{ marginTop: "12px", backgroundImage: "url("+savedMarker.src+")", backgroundSize: "cover" }}>
      <CardContent style={{ background: "linear-gradient(90deg, #f08026, transparent)" }}>
        <List style={{ paddingLeft: '0px'}}>
          <ListItem style={{ padding: '0px 0px 0px 12px' }}>
            <ListItemText primary={<b>{savedMarker.headline}</b>}/>
            <ListItemSecondaryAction>
              <Tooltip title="aktivieren" placement="top">
                <Fab
                  disabled={_activeImagePointer && _activeImagePointer._id === savedMarker._id}
                  onClick={() => activateImagePointer(savedMarker)}
                  style={{ marginRight: '10px' }}
                  size="small"
                  color="primary"
                >
                  <VisibilityIcon/>
                </Fab>
              </Tooltip>
              {/*
              <Tooltip title="verwalten" placement="top">
                <Fab style={{ marginRight: '10px' }} size="small" color="primary">
                  <EditIcon/>
                </Fab>
              </Tooltip>
              */}
              <Tooltip title="löschen" placement="top">
                <Fab
                  onClick={() => deleteImagePointer({ variables: { _id: savedMarker._id }})}
                  size="small"
                  color="secondary"
                >
                  <DeleteIcon/>
                </Fab>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  )

  return (
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom>
        <b>Marker</b>
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid align="center" item xs={12}>
              <Button startIcon={<AddIcon/>} onClick={props.onToggleMarkerPage} color="primary" variant="contained">Marker hinzufügen</Button>
              <Button disabled={_activeImagePointer === null} style={{ marginLeft: "10px" }} startIcon={<VisibilityOffIcon/>} onClick={() => updateActiveImagePointer({ variables: { _id: null }})} color="secondary" variant="contained">Marker ausblenden</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {listMarker}
    </Grid>
  );
}

export default Marker;
