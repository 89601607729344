import React, {useState} from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Fab from '@material-ui/core/Fab';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import './style.css';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import {CURRENT_WHEEL_ENTRIES} from "../../apollo/queries/currentWheelEntries";
import {ON_WHEEL_ENTRIES_CHANGED} from "../../apollo/subscriptions/onWheelEntriesChanged";
import {ADD_WHEEL_ENTRY} from "../../apollo/mutations/addWheelEntry";
import {DELETE_WHEEL_ENTRY} from "../../apollo/mutations/deleteWheelEntry";
import {CURRENT_WHEEL_RESULT} from "../../apollo/queries/currentWheelResult";
import {ON_WHEEL_RESULT_CHANGED} from "../../apollo/subscriptions/onWheelResultChanged";
import {TextField} from "@material-ui/core";
import {SET_CURRENT_WHEEL_RESULT} from "../../apollo/mutations/setCurrentWheelResult";
import CheckIcon from "@material-ui/icons/Check";
import ListItemText from "@material-ui/core/ListItemText";

const Games = () => {

  const [wheelEntryPosition, setWheelEntryPosition] = useState(null)
  const [wheelEntryName, setWheelEntryName] = useState(null)

  const { data: wheelResultData, error: wheelResultError, loading: wheelResultLoading } = useQuery(CURRENT_WHEEL_RESULT);
  const { data: wheelResultSubData } = useSubscription(ON_WHEEL_RESULT_CHANGED);

  const { data: wheelEntriesData, error: wheelEntriesError, loading: wheelEntriesLoading } = useQuery(CURRENT_WHEEL_ENTRIES);
  const { data: wheelEntriesSubData } = useSubscription(ON_WHEEL_ENTRIES_CHANGED);

  const [addWheelEntry] = useMutation(ADD_WHEEL_ENTRY);
  const [deleteWheelEntry] = useMutation(DELETE_WHEEL_ENTRY);
  const [setCurrentWheelResult] = useMutation(SET_CURRENT_WHEEL_RESULT);

  if(wheelEntriesLoading || wheelResultLoading) return (<p>Loading...</p>);
  if(wheelEntriesError || wheelResultError) return (<p>Error...</p>);

  const wheelResult = wheelResultSubData ? wheelResultSubData.onWheelResultChanged : wheelResultData.currentWheelResult
  const wheelEntries = wheelEntriesSubData ? wheelEntriesSubData.onWheelEntriesChanged : wheelEntriesData.currentWheelEntries

  const handleAddWheelEntry = () => {
    setWheelEntryName('')
    addWheelEntry({
      variables: {
        position: parseInt(wheelEntryPosition),
        name: wheelEntryName
      }
    })
  }

  const handleDeleteWheelEntry = (id) => {
    deleteWheelEntry({
      variables: {
        _id: id
      }
    })
  }

  const handleSetCurrentWheelResult = (id) => {
    setCurrentWheelResult({
      variables: {
        _id: id
      }
    })
  }

  const renderWheelEntries = () => {
    return wheelEntries.map(entry => (
        <ListItem>
          <ListItemText primary={"#"+entry.position+" "+entry.name}/>
          <ListItemSecondaryAction>
            <Fab disabled={wheelResult?._id === entry._id} onClick={() => handleSetCurrentWheelResult(entry._id)} size="small" color="primary" style={{ marginRight: "10px" }}>
              <CheckIcon/>
            </Fab>
            <Fab disabled={wheelResult?._id === entry._id} onClick={() => handleDeleteWheelEntry(entry._id)} size="small" color="secondary">
              <RemoveIcon/>
            </Fab>
          </ListItemSecondaryAction>
        </ListItem>
    ))
  }

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent align="center">
          <Typography variant="h4">
            <b>{wheelResult?.name}</b>
          </Typography>
          <Typography variant="h6">
            <b>Aktuelles Spiel</b>
          </Typography>
        </CardContent>
      </Card>
      <br/>
      <Card>
        <CardContent align="center">
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <TextField
                  value={wheelEntryPosition}
                  onChange={(event) => setWheelEntryPosition(event.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Position'}
                  variant={'outlined'}/>
            </Grid>
            <Grid item xs={8}>
              <TextField
                  value={wheelEntryName}
                  onChange={(event) => setWheelEntryName(event.target.value)}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label={'Name des Spiels'}
                  variant={'outlined'}/>
            </Grid>
            <Grid item xs={2}>
              <Fab onClick={() => handleAddWheelEntry()} size="large" color="primary" disabled={!wheelEntryPosition || !wheelEntryName}>
                <AddIcon/>
              </Fab>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br/>
      <Card>
        <CardContent align="center">
          <List style={{padding:"0px"}}>
            {renderWheelEntries()}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Games;
