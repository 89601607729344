import { gql } from '@apollo/client';

export const ACTIVE_IMAGE_POINTER = gql`
  query {
    activeImagePointer {
      _id
      headline
      src
      x
      y
    }
  }
`;
