import React from 'react';
import { Route } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import './style.css';

const Navigation = (props) => {

  const renderItems = () =>
  props.items
  .map(item => (
    <Route render={({ history}) => (
      <Button disabled={item.props.item.disabled} onClick={() => { history.push(item.props.item.path) }} color="inherit">{item.props.item.name}</Button>
    )} />
  ));

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography style={{ flexGrow: 1 }} variant="h6">
          Pandor Interactive Systems
        </Typography>
        {renderItems()}
      </Toolbar>
    </AppBar>
  )
}

export default Navigation;
