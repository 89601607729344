import { gql } from '@apollo/client';

export const ON_IMAGE_POINTERS_UPDATED = gql`
  subscription {
    onImagePointersUpdated {
      _id
      headline
      src
      x
      y
    }
  }
`;
