import { gql } from '@apollo/client';

export const ADD_WHEEL_ENTRY = gql`
  mutation addWheelEntry($position: Int!, $name: String!) {
    addWheelEntry(position: $position, name: $name){
        _id
        position
        name
    }
  }
`;
