import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './style.scss'
import Grid from "@material-ui/core/Grid";
import {useQuery, useSubscription} from "@apollo/client";
import {CURRENT_WHEEL_ENTRIES} from "../../../apollo/queries/currentWheelEntries";
import {ON_WHEEL_ENTRIES_CHANGED} from "../../../apollo/subscriptions/onWheelEntriesChanged";
import {CURRENT_WHEEL_RESULT} from "../../../apollo/queries/currentWheelResult";
import {ON_WHEEL_RESULT_CHANGED} from "../../../apollo/subscriptions/onWheelResultChanged";
import BezierEasing from 'bezier-easing';

let rotateCarousel = () => void 0;

function carousel(root) {
    var
        figure = root.querySelector('figure'),
        images = figure.children,
        n = images.length,
        gap = root.dataset.gap || 0,
        bfc = 'bfc' in root.dataset,
        theta = 2 * Math.PI / n,
        currImage = 0;

    setupCarousel(n, parseFloat(getComputedStyle(images[0]).height));

    function setupCarousel(n, s) {
        var apothem = s / (2 * Math.tan(Math.PI / n));

        figure.style.transformOrigin = `50% 50% ${-apothem}px`;

        for (var i = 0; i < n; i++)
            images[i].style.padding = `${gap}px`;
        for (i = 1; i < n; i++) {

            images[i].style.transformOrigin = `50% 50% ${-apothem}px`;
            images[i].style.transform = `rotateX(${i * theta}rad)`;
        }
        if (bfc)
            for (i = 0; i < n; i++)
                images[i].style.backfaceVisibility = 'hidden';
        rotateCarousel(currImage);
    }

    rotateCarousel = function (imageIndex) {
        figure.style.transform = `rotateX(${imageIndex * -theta}rad)`;
    }

}

const Game = () => {
    const {
        data: wheelEntries,
        error: wheelEntriesError
    } = useQuery(CURRENT_WHEEL_ENTRIES);
    const {data: wheelEntriesSubData} = useSubscription(ON_WHEEL_ENTRIES_CHANGED);

    const {data: currentEntry} = useQuery(CURRENT_WHEEL_RESULT);
    const {data: currentEntrySubData} = useSubscription(ON_WHEEL_RESULT_CHANGED);

    const entries = (wheelEntriesSubData?.onWheelEntriesChanged || wheelEntries?.currentWheelEntries)?.sort((a, b) => a.position - b.position);
    const result = currentEntrySubData?.onWheelResultChanged || currentEntry?.currentWheelResult;

    const spinMemory = useMemo(() => ({index: 0, timeout: null}), []);
    const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
    const spin = useCallback(() => {
        if (spinMemory.timeout) clearTimeout(spinMemory.timeout);
        const index = (result && entries) ? entries.map(x => x._id).indexOf(result._id) : 0;
        const [minSkips, maxSkips] = [80, 120];
        let randomSkips = Math.floor(Math.random() * (maxSkips - minSkips + 1) + minSkips);
        const [minSpeed, maxSpeed] = [40, 80];
        const endSpeed = 500;
        const randomSpeed = Math.floor(Math.random() * (maxSpeed - minSpeed + 1) + minSpeed);

        if (!entries || entries.length === 0) return;
        for (let i = 0; true; i++) {
            if (((spinMemory.index + randomSkips + i) % entries.length) === index) {
                randomSkips += i;
                break;
            }
        }

        const phases = [];
        for (let i = 1; i <= randomSkips; i++) {
            const progress = BezierEasing(1, .01, .97, 1)(i / randomSkips);
            const ms = (endSpeed - randomSpeed) * progress + randomSpeed;
            const index = i + spinMemory.index;
            phases.push({ms, index, active: index % entries.length});
        }

        let func = () => {
            const currentPhase = phases.shift();
            rotateCarousel(currentPhase.index);
            spinMemory.index = currentPhase.index;
            setCurrentActiveIndex(currentPhase.active);
            if (phases[0]) spinMemory.timeout = setTimeout(func, phases[0].ms);
        };
        func();
    }, [spinMemory, result, entries]);

    const rotateOnShortestWay = useCallback((index) => {
        let lastIndex = spinMemory.index;
        while ((lastIndex + 1) % (index + 1) !== 0)
            lastIndex++;
        spinMemory.index = lastIndex;
        rotateCarousel(lastIndex);
        setCurrentActiveIndex(index);
    }, []);

    useEffect(() => {
        var carousels = document.querySelectorAll('.carousel');

        for (var i = 0; i < carousels.length; i++) {
            carousel(carousels[i]);
        }
        const index = (result && entries) ? entries.map(x => x._id).indexOf(result._id) : 0;
        rotateOnShortestWay(index);
    }, [entries])

    useEffect(() => spin(), [result]);

    return (
        <Grid direction="row" alignItems="center" justify="center" container spacing={5}
              style={{minHeight: '100vh'}}>
            <Grid item xs={4} style={{textAlign: "right", padding: "0px"}}>
                <div className="arrow-right"/>
            </Grid>
            <Grid item xs={4} style={{padding: "0px"}}>
                <div className="carousel">
                    <figure>
                        {entries && entries.map((entry, index) => <div
                            className={'carousel-item'}
                            key={entry._id}>{entry.name}</div>)}
                        {!entries &&
                        <div className={'carousel-item'}>{wheelEntriesError ? 'Error.' : 'Loading...'}</div>}
                    </figure>
                    {/*<nav>
                        <button className="nav prev">Prev</button>
                        <button className="nav next">Next</button>
                    </nav>*/}
                </div>
            </Grid>
            <Grid item xs={4} style={{textAlign: "left", padding: "0px"}}>
                <div className="arrow-left"/>
            </Grid>
        </Grid>
    )
}

export default Game;
