import { gql } from '@apollo/client';

export const CREATE_PLAYER = gql`
  mutation createPlayer($user: UserInput!) {
    createPlayer(user: $user) {
      _id
      color
      name
      joinCode
      winnerRounds
      points
    }
  }
`;
