import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import './style.css';

const Waiting = () => {

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent align="center">
          Die Spieler werden willkommen geheißen, wähle einen Spielmodus, um fortzufahren.
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Waiting;
