import { gql } from '@apollo/client';

export const ON_PLAYERS_CHANGED = gql`
  subscription {
    onPlayersChanged {
      _id
      color
      name
      joinCode
      winnerRounds
      points
    }
  }
`;

export const ON_PLAYERS_CHANGED_PLAYERVIEW = gql`
  subscription {
    onPlayersChanged {
      _id
      color
      name
      winnerRounds
      points
    }
  }
`;
