import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import SDS_Logo from '../../../resources/SDS_Logo.png';

import './style.css';

import BuzzerView from './BuzzerView';
import MarkerView from './MarkerView';
import JoiningView from './JoiningView';
import FullpageLoader from '../../../auth/FullpageLoader';

import { useQuery, useSubscription } from '@apollo/client';

import { ME } from '../../../apollo/queries/me';
import { CURRENT_GAMESTATE } from '../../../apollo/queries/currentGamestate';
import { ON_GAMESTATE_UPDATE } from '../../../apollo/subscriptions/onGamestateUpdate';
import { ALL_PLAYERS_PLAYERVIEW } from '../../../apollo/queries/allPlayers';
import { ON_PLAYERS_CHANGED_PLAYERVIEW } from '../../../apollo/subscriptions/onPlayersChanged';

const PlayerView = () => {

  const { data: meData, error: meError, loading: meLoading } = useQuery(ME);
  const { data: currentGamestateData, error: currentGamestateError, loading: currentGamestateLoading } = useQuery(CURRENT_GAMESTATE);
  const { data: currentGamestateSubData } = useSubscription(ON_GAMESTATE_UPDATE);
  const { data: allPlayersData, error: allPlayersError, loading: allPlayersLoading } = useQuery(ALL_PLAYERS_PLAYERVIEW);
  const { data: allPlayersSubData } = useSubscription(ON_PLAYERS_CHANGED_PLAYERVIEW);

  if(currentGamestateLoading || meLoading || allPlayersLoading) return (<p>Loading...</p>);
  if(currentGamestateError || meError || allPlayersError) return (<p>Error...</p>);

  const me = meData.me;
  const currentGamestate = currentGamestateSubData ? currentGamestateSubData.onGamestateUpdate : currentGamestateData.currentGamestate;
  const allPlayers = allPlayersSubData ? allPlayersSubData.onPlayersChanged : allPlayersData.allPlayers

  const userData = allPlayers.filter(e => e._id === me);
  if(!allPlayers.some(e => e._id === me)) return (<FullpageLoader error="Du wurdest vom Spielleiter gekickt..." />)


  return (
    <Container>
      <div style={{ borderTop: "5px solid "+userData[0].color }} className="topBar">
        <Grid container spacing={3}>
          <Grid align="left" item xs={6}>
            <Typography variant="h5" gutterBottom>
              <b>{userData[0].name}</b>
            </Typography>
          </Grid>
          <Grid align="right" item xs={6}>

          </Grid>
        </Grid>
      </div>
      <Grid container spacing={3} alignContent="center" style={{ minHeight: '100vh' }}>
        <Grid align="center" item xs={12}>
          <img alt="SDS-Logo" src={SDS_Logo} width="200px"/>
        </Grid>
        <Grid align="center" item xs={12}>
          {currentGamestate === "JOINING" &&
          <JoiningView user={userData[0]}/>}
          {currentGamestate === "BUZZER" &&
          <BuzzerView user={userData[0]}/>}
          {currentGamestate === "IMAGE_POINTER" &&
          <MarkerView user={userData[0]}/>}
        </Grid>
      </Grid>
    </Container>
  );
}

export default PlayerView;
