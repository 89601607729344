import { gql } from '@apollo/client';

export const SET_GAMELEVEL_SETTINGS = gql`
  mutation setGameLevelSettings($settings: GameLevelSettingsInput!) {
    setGameLevelSettings(settings: $settings) {
      levels
      lives
      counterMax
      saveZones
      rechargeLiveZones
      levelNames
    }
  }
`;
