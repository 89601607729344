import { gql } from '@apollo/client';

export const CURRENT_WHEEL_ENTRIES = gql`
  query {
    currentWheelEntries {
        _id
        position
        name
    }
  }
`;
