import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import './App.css';

import AuthLoader from './auth/AuthLoader';

import { ApolloLink, ApolloProvider, concat } from '@apollo/client';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from '@apollo/client/link/ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';

import { getToken } from './tokenProvider';

import Content from './views/Content';
import PanelContent from './views/PanelContent';
import SDSOverlay from './views/SDS/SDSOverlay';
import PlayerView from './views/SDS/PlayerView';
import GDZOverlay from './views/GDZ/GDZOverlay';

// Themes
import pandorTheme from './themes/pandorTheme/pandorTheme';

const App = () => {

  const [error, setError] = React.useState("");

  const httpLink = new HttpLink({
    uri: window.env.GRAPHQL_URL
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
      headers: {
        authorization: getToken() ? localStorage.getItem('joinCode') : "",
      }
    });
    return forward(operation);
  });

  const wsLink = new WebSocketLink({
    uri: window.env.WEBSOCKET_URL,
    options: {
      reconnect: true,
      connectionParams: () => ({
        authorization: getToken() ? localStorage.getItem('joinCode') : ""
      })
    }
  },
  {
    requeryOnReconnect: ({ query: { definitions } }) => definitions.some(
      ({ kind, operation }) =>
        kind === 'OperationDefinition' && operation === 'query',
    ),
  });

  const splittedHttpLink = split(
    // split based on operation type
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  const onErrorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ name, message, locations, path }) => {
        console.log(`[GraphQL error]: Name: ${name}, Message: ${message}, Location: ${locations}, Path: ${path}`);
        setError(`[GraphQL error]: Name: ${name}, Message: ${message}, Location: ${locations}, Path: ${path}`);
        return false;
      });

    if (networkError)
      console.log(`[Network error]: ${networkError}`);
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const link = concat(authMiddleware, onErrorLink.concat(splittedHttpLink));
  const cache = new InMemoryCache();

  const client = new ApolloClient({
    link,
    cache,
    defaultOptions,
    queryDeduplication: false,
  });

  return (
    <ApolloProvider client={client}>
      <MuiThemeProvider theme={pandorTheme}>
      <CssBaseline />
        <BrowserRouter>
          <AuthLoader error={error}>
            <Content>
              <Switch>
                <Route path="/sds/play" component={PlayerView} />
                <Route path="/sds/overlay" component={SDSOverlay} />
                <Route path="/gdz/overlay" component={GDZOverlay} />
                <Route path="/" component={PanelContent} />
              </Switch>
            </Content>
          </AuthLoader>
        </BrowserRouter>
      </MuiThemeProvider>
    </ApolloProvider>
  );
}

class AppContent extends Component {
  render () {
    return (
      <App/>
    )
  }
}

export default AppContent;
