import React, {useCallback, useEffect} from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';

import './style.css';

import VolumeUp from '@material-ui/icons/VolumeUp';

import buzzSound from '../../../resources/buzzsound.mp3';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { BUZZER_ENABLED } from '../../../apollo/queries/buzzerEnabled';
import { BUZZ } from '../../../apollo/mutations/buzz';
import { ON_BUZZER_ENABLED } from '../../../apollo/subscriptions/onBuzzerEnabled';
import { ON_BUZZ_PLAYERVIEW } from '../../../apollo/subscriptions/onBuzz';

const BuzzerView = (props) => {

  const localVolume = localStorage.getItem('localVolume')
  const [volume, setVolume] = React.useState(localVolume ? localVolume : 0.3);

  const audio = new Audio(buzzSound);

  const { data: buzzerEnabledData, error: buzzerEnabledError, loading: buzzerEnabledLoading } = useQuery(BUZZER_ENABLED);
  const [buzz] = useMutation(BUZZ);
  const { data: buzzerEnabledSubData } = useSubscription(ON_BUZZER_ENABLED);
  useSubscription(ON_BUZZ_PLAYERVIEW, {
    onSubscriptionData: data => {
      if(data.subscriptionData.data.onBuzz._id === props.user._id) {
        audio.volume = 0.1;
        audio.play();
      }
    }
  });

  const buzzerEnabled = buzzerEnabledSubData ? buzzerEnabledSubData.onBuzzerEnabled : buzzerEnabledData?.buzzerEnabled || false;

  const handleUserKeyPress = useCallback(({code}) => code.toLowerCase() === 'space' && buzzerEnabled ? buzz() : null, [buzz, buzzerEnabled])

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => window.removeEventListener('keydown', handleUserKeyPress);
  }, [handleUserKeyPress]);

  if(buzzerEnabledLoading) return (<p>Loading...</p>);
  if(buzzerEnabledError) return (<p>Error...</p>);


  const handleChangeVolume = (event, newValue) => {
    setVolume(newValue);
    localStorage.setItem('localVolume', newValue)
  };

  return (
    <div>
      <Button disabled={!buzzerEnabled} onClick={() => buzz()} variant="contained" className="buzzer"></Button>
      <Grid style={{ marginTop: '5px' }} container spacing={2} justify="center">
        <Grid item xs={2}>
          <Slider
            style={{ marginTop: '2px' }}
            step={0.1}
            min={0.0}
            max={1.0}
            value={volume}
            onChange={handleChangeVolume}
          />
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => {
              audio.volume = volume;
              audio.play()
            }}
            size="small"
          >
            <VolumeUp />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
}

export default BuzzerView;
