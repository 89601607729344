import React, { useEffect } from 'react';

import './style.css';

import { listenOnObject, pipe } from 'image-pointer';

import { useMutation } from '@apollo/client';

import { SET_POINTER_PLAYERVIEW } from '../../../apollo/mutations/setPointer';

const LoadImage = (props) => {

  const [setPointer] = useMutation(SET_POINTER_PLAYERVIEW);

  const createMarker = (name, color) => {
    const marker = document.createElement('span');
    const text = document.createElement('div');
    text.innerText = name;
    const dot = document.createElement('div');
    dot.style.backgroundColor = color;
    marker.append(text, dot);
    return marker;
  }

  useEffect(() => {
    const source_local = listenOnObject('source', {disableInactive: true, interval: 16});
    const source = listenOnObject('source', {disableInactive: true});
    source_local.onMove(pipe('source', {customMarker: createMarker(props.user.name, props.user.color), markerAlign: 'center'}))

    source.onMove((x,y,active) => {
      setPointer({
        variables: {
          update: {
            x: x,
            y: y,
            active: active
          }
        }
      })
    });
    return () => [source, source_local].forEach(x => x.dispose());
  }, [])

  return (
    <div className="block">
      <img className="background" width="100%" src={props.activeImagePointer.src} alt={props.activeImagePointer.headline} id="source"/>
    </div>
  );
}

export default LoadImage;
