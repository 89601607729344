import React from 'react';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import GDZ_Logo from '../../resources/GDZ_Logo.png';

import Lives from './Lives';
import Counter from './Counter';
import Level from './Level';

import './style.css';
import Games from "./Games";
import Settings from "./Settings";
import Controller from "./Controller";

const GDZ = () => {

  return (
    <Container>
      <Grid container spacing={3} alignContent="center" style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Grid align="center" item xs={12}>
          <img alt="GDZ_Logo" src={GDZ_Logo} width="200px"/>
        </Grid>
        <Grid item xs={12} md={12}>
          <Level/>
        </Grid>
        <Grid item xs={12} md={6}>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Lives/>
            </Grid>
            <Grid item xs={12}>
              <Games/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller/>
            </Grid>
            <Grid item xs={12}>
              <Counter/>
            </Grid>
            <Grid item xs={12}>
              <Settings/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default GDZ;
