import React from 'react';
import { Route } from 'react-router-dom'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import SDS_Logo from '../../resources/SDS_Logo.png';
import GDZ_Logo from '../../resources/GDZ_Logo.png';

import './style.css';

const Overview = () => {

  const shortcuts = [
    {
      section: 'SDS',
      name: 'Punktestand',
      url: window.location.origin+"/sds/overlay?content=scoreboard&c="+localStorage.getItem('joinCode')
    },
    {
      section: 'SDS',
      name: 'Buzzerrahmen Teilnehmer 1',
      url: window.location.origin+"/sds/overlay?content=first_attendee&c="+localStorage.getItem('joinCode')
    },
    {
      section: 'SDS',
      name: 'Buzzerrahmen Teilnehmer 2',
      url: window.location.origin+"/sds/overlay?content=second_attendee&c="+localStorage.getItem('joinCode')
    },
    {
      section: 'SDS',
      name: 'Marker',
      url: window.location.origin+"/sds/overlay?content=marker&c="+localStorage.getItem('joinCode')
    },
    {
      section: 'GDZ',
      name: 'Levelboard',
      url: window.location.origin+"/gdz/overlay?content=level&c="+localStorage.getItem('joinCode')
    },
    {
      section: 'GDZ',
      name: 'Spielerad',
      url: window.location.origin+"/gdz/overlay?content=game&c="+localStorage.getItem('joinCode')
    },
    {
      section: 'GDZ',
      name: 'Lebensanzeige',
      url: window.location.origin+"/gdz/overlay?content=lives&c="+localStorage.getItem('joinCode')
    },
    {
      section: 'GDZ',
      name: 'Zähler',
      url: window.location.origin+"/gdz/overlay?content=counter&c="+localStorage.getItem('joinCode')
    }
  ]

  const [copiedURL, setCopiedURL] = React.useState("");

  function copyURL(copyText) {
    navigator.clipboard.writeText(copyText).then(function() {
      setCopiedURL(copyText)
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  const renderShortcuts = (section) =>
  shortcuts
  .filter(shortcut => shortcut.section === section)
  .map(shortcut => (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={6} md={6}>
          <Typography style={{ marginTop: "8px" }} gutterBottom variant="body2">
            {shortcut.name}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Tooltip title="Link kopieren" placement="top">
            <Fab disabled={copiedURL === shortcut.url} onClick={() => copyURL(shortcut.url)} style={{ marginRight: '10px' }} size="small" color="primary">
              <FileCopyIcon/>
            </Fab>
          </Tooltip>
        </Grid>
      </Grid>
      <Divider style={{ margin: "12px" }}/>
    </div>
  ));

  return(
    <Container>
      <Grid container spacing={3} alignContent="center" style={{ minHeight: 'calc(100vh - 64px)' }}>
        <Grid item xs={12} md={3}/>
        <Grid align="center" item xs={12} md={3}>
          <Card>
            <CardContent>
              <img alt="SDS-Logo" src={SDS_Logo} width="200px"/>
              <Divider style={{ margin: "12px" }}/>
              {renderShortcuts("SDS")}
              <Route render={({ history}) => (
                <Button variant="contained" color="primary" onClick={() => { history.push('/sds') }}>Verwalten</Button>
              )} />
            </CardContent>
          </Card>
        </Grid>
        <Grid align="center" item xs={12} md={3}>
          <Card>
            <CardContent>
              <img alt="GDZ_Logo" src={GDZ_Logo} width="200px"/>
              <Divider style={{ margin: "12px" }}/>
              {renderShortcuts("GDZ")}
              <Route render={({ history}) => (
                <Button variant="contained" color="primary" onClick={() => { history.push('/gdz') }}>Verwalten</Button>
              )} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={3}/>
      </Grid>
    </Container>
  )
}

export default Overview;
