import React, {useEffect} from 'react';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import './style.css';

import { useQuery, useSubscription } from '@apollo/client';

import { CURRENT_LEVEL } from '../../../apollo/queries/currentLevel';
import { GAMELEVEL_SETTINGS } from '../../../apollo/queries/gameLevelSettings';
import { ON_CURRENT_LEVEL_CHANGED } from '../../../apollo/subscriptions/onCurrentLevelChanged';
import { ON_GAMELEVEL_SETTINGS_CHANGED } from '../../../apollo/subscriptions/onGameLevelSettingsChanged';

const Levelboard = () => {


  const { data: currentLevelData, error: currentLevelError, loading: currentLevelLoading } = useQuery(CURRENT_LEVEL);
  const { data: currentLevelSubData } = useSubscription(ON_CURRENT_LEVEL_CHANGED);
  const { data: settingsData, error: settingsError, loading: settingsLoading } = useQuery(GAMELEVEL_SETTINGS);
  const { data: settingsSubData } = useSubscription(ON_GAMELEVEL_SETTINGS_CHANGED);

  if(currentLevelLoading || settingsLoading) return (<p>Loading...</p>);
  if(currentLevelError || settingsError) return (<p>Error...</p>);

  const currentLevel = currentLevelSubData ? currentLevelSubData.onCurrentLevelChanged : currentLevelData.currentLevel
  const settings = settingsSubData ? settingsSubData.onGameLevelSettingsChanged : settingsData.gameLevelSettings

  function renderLevelGrid() {
    var levelMap = [];
    for (var i = 1; i <= settings.levels; i++) {
      if(i === currentLevel) {
        levelMap.push(
          <div
            className={i < settings.rechargeLiveZones.sort((a, b) => a - b)[0] ? "level-container-first noob-level" : "level-container-first"}
            style={{ border: settings.saveZones.includes(i) && "3px solid #F8D800", background: i === settings.levels && "linear-gradient( 180deg, #d21f3c 10%, #ff546d 100%)" }}
            key={i}
          >
            <Container>
              <Typography className="level-value" style={{ marginBottom: "0px", textAlign: "center", fontSize: "20px" }} variant="h5" component="h5" gutterBottom>
                Level {i} {settings.levelNames[i-1] ? ("- " + settings.levelNames[i-1]) : ""}
              </Typography>
            </Container>
          </div>
        );
      } else if(i === currentLevel-1 || i === currentLevel+1) {
        levelMap.push(
          <div
            className={i < settings.rechargeLiveZones.sort((a, b) => a - b)[0] ? "level-container-second noob-level" : "level-container-second"}
            style={{ border: settings.saveZones.includes(i) && "3px solid #F8D800", background: i === settings.levels && "linear-gradient( 180deg, #d21f3c 10%, #ff546d 100%)" }}
            key={i}
          >
            <Container>
              <Typography className="level-value" style={{ marginBottom: "0px", textAlign: "center", fontSize: "20px" }} variant="h5" component="h5" gutterBottom>
                Level {i} {settings.levelNames[i-1] ? ("- " + settings.levelNames[i-1]) : ""}
              </Typography>
            </Container>
          </div>
        );
      } else if(i === currentLevel-2 || i === currentLevel+2) {
        levelMap.push(
          <div
            className={i < settings.rechargeLiveZones.sort((a, b) => a - b)[0] ? "level-container-third noob-level" : "level-container-third"}
            style={{ border: settings.saveZones.includes(i) && "3px solid #F8D800", background: i === settings.levels && "linear-gradient( 180deg, #d21f3c 10%, #ff546d 100%)" }}
            key={i}
          >
            <Container>
              <Typography className="level-value" style={{ marginBottom: "0px", textAlign: "center", fontSize: "20px" }} variant="h5" component="h5" gutterBottom>
                Level {i} {settings.levelNames[i-1] ? ("- " + settings.levelNames[i-1]) : ""}
              </Typography>
            </Container>
          </div>
        );
      } else if(i === currentLevel+3) {
        levelMap.push(
          <div
            className={i < settings.rechargeLiveZones.sort((a, b) => a - b)[0] ? "level-container-fourth noob-level" : "level-container-fourth"}
            style={{ border: settings.saveZones.includes(i) && "3px solid #F8D800", background: i === settings.levels && "linear-gradient( 180deg, #d21f3c 10%, #ff546d 100%)" }}
            key={i}
          >
            <Container>
              <Typography className="level-value" style={{ marginBottom: "0px", textAlign: "center", fontSize: "20px" }} variant="h5" component="h5" gutterBottom>
                Level {i} {settings.levelNames[i-1] ? ("- " + settings.levelNames[i-1]) : ""}
              </Typography>
            </Container>
          </div>
        );
      } else {
        levelMap.push(
          <div
            className={i < settings.rechargeLiveZones.sort((a, b) => a - b)[0] ? "level-container-fifth noob-level" : "level-container-fifth"}
            style={{ border: settings.saveZones.includes(i) && "3px solid #F8D800", background: i === settings.levels && "linear-gradient( 180deg, #d21f3c 10%, #ff546d 100%)" }}
            key={i}
          >
            <Container>
              <Typography className="level-value" style={{ marginBottom: "0px", textAlign: "center", fontSize: "20px" }} variant="h5" component="h5" gutterBottom>
                Level {i} {settings.levelNames[i-1] ? ("- " + settings.levelNames[i-1]) : ""}
              </Typography>
            </Container>
          </div>
        );
      }

    }
    return levelMap.reverse();
  }

  return (
    <Container>
      <Grid direction="row" alignItems="center" justify="center" container spacing={5} style={{ minHeight: '100vh' }}>
        <Grid item xs={4} style={{ textAlign: "right" }}>

        </Grid>
        <Grid item xs={4} style={{ padding: "0px" }}>
          <div className="level-container">
            <div className="level-wrapper">
              {renderLevelGrid()}
            </div>
          </div>
        </Grid>
        <Grid item xs={4} style={{ textAlign: "left" }}>

        </Grid>
      </Grid>
    </Container>
  );
}

export default Levelboard;
