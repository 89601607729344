const token = localStorage.getItem('joinCode');
let url = new URL(window.location.href)
let params = new URLSearchParams(url.search);

export function getToken() {
  if(token) {
    return token;
  } else {
    return false;
  }
}

export function setToken() {
  localStorage.setItem("joinCode", "")
  let originalUrl = window.location.href
  if(params.get("c")) {
    if(params.get("c") === "QuasiTwasi") {
      if(!params.get("content")) {
        window.location.href = "/"
      } else {
        window.location.href = originalUrl
      }
    } else {
      window.location.href = originalUrl
    }
    localStorage.setItem("joinCode", params.get("c"))
  }
}
