import { gql } from '@apollo/client';

export const CREATE_IMAGE_POINTER = gql`
  mutation createImagePointer($data: ImagePointerInput!) {
    createImagePointer(data: $data) {
      _id
      headline
      src
      x
      y
    }
  }
`;
