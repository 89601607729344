import { gql } from '@apollo/client';

export const GAMELEVEL_SETTINGS = gql`
  query {
    gameLevelSettings {
      levels
      lives
      counterMax
      saveZones
      rechargeLiveZones
      levelNames
    }
  }
`;
