import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';

import ColorPicker from 'material-ui-color-picker'

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import './style.css';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { ALL_PLAYERS } from '../../apollo/queries/allPlayers';
import { CREATE_PLAYER } from '../../apollo/mutations/createPlayer';
import { REMOVE_PLAYER } from '../../apollo/mutations/removePlayer';
import { ON_PLAYERS_CHANGED } from '../../apollo/subscriptions/onPlayersChanged';
import { ON_BUZZ } from '../../apollo/subscriptions/onBuzz';
import { ON_BUZZER_ENABLED } from '../../apollo/subscriptions/onBuzzerEnabled';

function shadeColor(color, percent) {
  if(color !== undefined)
  var R = parseInt(color.substring(1,3),16);
  var G = parseInt(color.substring(3,5),16);
  var B = parseInt(color.substring(5,7),16);
  R = Math.floor(R * (100 + percent) / 100);
  G = Math.floor(G * (100 + percent) / 100);
  B = Math.floor(B * (100 + percent) / 100);
  R = (R<255)?R:255;
  G = (G<255)?G:255;
  B = (B<255)?B:255;
  var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
  return "#"+RR+GG+BB;
}

const Attendees = () => {

  const [color, setColor] = React.useState("#FFFFFF");
  const [name, setName] = React.useState("");
  const [playerToEdit, setPlayerToEdit] = React.useState("");
  const [copiedURL, setCopiedURL] = React.useState("");
  const [visibleURL, setVisibleURL] = React.useState("");

  const { data: allPlayersData, error: allPlayersError, loading: allPlayersLoading } = useQuery(ALL_PLAYERS);
  const { data: allPlayersSubData } = useSubscription(ON_PLAYERS_CHANGED);
  const { data: buzzSubData } = useSubscription(ON_BUZZ);
  const { data: buzzerEnabledSubData } = useSubscription(ON_BUZZER_ENABLED);
  const [createPlayer] = useMutation(CREATE_PLAYER, {
    variables: {
      user: {
        color: color,
        name: name
      }
    },
    onCompleted: data => {
      setName("")
    }
  });
  const [removePlayer] = useMutation(REMOVE_PLAYER);

  if(allPlayersLoading) return (<p>Loading...</p>);
  if(allPlayersError) return (<p>Error...</p>);

  const allPlayers = allPlayersSubData ? allPlayersSubData.onPlayersChanged : allPlayersData.allPlayers

  const handleChangeColor = (color) => {
    if(color)
      setColor(color.toUpperCase())
  }

  function copyURL(copyText) {
    navigator.clipboard.writeText(copyText).then(function() {
      setCopiedURL(copyText)
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  const listAttendees = allPlayers.map((attendee) =>
    <Card style={{ marginTop: '12px', padding: '0px', borderLeft: '5px solid ' + attendee.color }}>
      <CardContent style={{ paddingTop: "0px", paddingBottom: "0px", backgroundColor: buzzSubData && !buzzerEnabledSubData.onBuzzerEnabled && buzzSubData.onBuzz._id === attendee._id && shadeColor(attendee.color, -65) }}>
        <List style={{ paddingLeft: '0px' }}>
          <ListItem style={{ padding: '0px 0px 0px 12px' }}>
            <ListItemText
              primary={<b>{attendee.name} | {attendee.points} {attendee.points === 1 ? "Punkt" : "Punkte"}</b>}
              secondary={<span style={{ userSelect: visibleURL !== attendee._id && "none", color: visibleURL !== attendee._id && "transparent", textShadow: visibleURL !== attendee._id && "0 0 8px rgba(255,255,255,1)" }}>{window.location.origin+"/sds/play?c="+attendee.joinCode}</span>} />
            {playerToEdit && playerToEdit === attendee._id ?
            <ListItemSecondaryAction>
              <Tooltip title="speichern" placement="top">
                <Fab onClick={() => setPlayerToEdit("")} size="small" color="primary">
                  <CheckIcon/>
                </Fab>
              </Tooltip>
            </ListItemSecondaryAction>
            :
            <ListItemSecondaryAction>
              <Tooltip title="Link kopieren" placement="top">
                <Fab disabled={copiedURL === window.location.origin+"/sds/play?c="+attendee.joinCode} onClick={() => copyURL(window.location.origin+"/sds/play?c="+attendee.joinCode)} style={{ marginRight: '10px' }} size="small" color="primary">
                  <FileCopyIcon/>
                </Fab>
              </Tooltip>
              {visibleURL !== attendee._id ?
                <Tooltip title="Link anzeigen" placement="top">
                  <Fab onClick={() => setVisibleURL(attendee._id)} style={{ marginRight: '10px' }} size="small" color="primary">
                    <VisibilityIcon/>
                  </Fab>
                </Tooltip>
                :
                <Tooltip title="Link verbergen" placement="top">
                  <Fab onClick={() => setVisibleURL("")} style={{ marginRight: '10px' }} size="small" color="primary">
                    <VisibilityOffIcon/>
                  </Fab>
                </Tooltip>
              }

              {/*
              <Tooltip title="bearbeiten" placement="top">
                <Fab onClick={() => setPlayerToEdit(attendee._id)} style={{ marginRight: '10px' }} size="small" color="primary">
                  <EditIcon/>
                </Fab>
              </Tooltip>
              */}
              <Tooltip title={attendee.name + " kicken"} placement="top">
                <Fab onClick={() => removePlayer({variables: { _id: attendee._id }})} size="small" color="secondary">
                  <ExitToAppIcon/>
                </Fab>
              </Tooltip>
            </ListItemSecondaryAction>}
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        <b>Teilnehmer</b>
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={3} alignContent="center">
            <Grid item xs={7}>
              <TextField
                value={name}
                onChange={(event) => setName(event.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                label="Name"
                variant="outlined"
              />
            </Grid>
            <Grid className="colorPicker" item xs={3}>
              <ColorPicker
                fullWidth
                variant="outlined"
                readOnly
                label="Farbe"
                inputProps={{
                  value: color
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={value => handleChangeColor(value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Fab onClick={() => createPlayer()} size="large" color="primary" disabled={!color || !name}>
                <AddIcon/>
              </Fab>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {listAttendees}
    </div>
  )
}

export default Attendees;
