import React from 'react';
import Grid from '@material-ui/core/Grid';

import './style.css';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {useMutation, useQuery, useSubscription} from "@apollo/client";
import {SET_CURRENT_LEVEL} from "../../apollo/mutations/setCurrentLevel";
import {SET_GAMELEVEL_SETTINGS} from "../../apollo/mutations/setGameLevelSettings";
import {CURRENT_LEVEL} from "../../apollo/queries/currentLevel";
import {ON_CURRENT_LEVEL_CHANGED} from "../../apollo/subscriptions/onCurrentLevelChanged";
import {GAMELEVEL_SETTINGS} from "../../apollo/queries/gameLevelSettings";
import {ON_GAMELEVEL_SETTINGS_CHANGED} from "../../apollo/subscriptions/onGameLevelSettingsChanged";
import {SET_CURRENT_LIVES} from "../../apollo/mutations/setCurrentLives";
import {CURRENT_LIVES} from "../../apollo/queries/currentLives";
import {ON_CURRENT_LIVES_CHANGED} from "../../apollo/subscriptions/onCurrentLivesChanged";

const Settings = () => {

    const { data: currentLivesData, error: currentLivesError, loading: currentLivesLoading } = useQuery(CURRENT_LIVES);
    const { data: currentLivesSubData } = useSubscription(ON_CURRENT_LIVES_CHANGED);
    const { data: currentLevelData, error: currentLevelError, loading: currentLevelLoading } = useQuery(CURRENT_LEVEL);
    const { data: currentLevelSubData } = useSubscription(ON_CURRENT_LEVEL_CHANGED);
    const { data: settingsData, error: settingsError, loading: settingsLoading } = useQuery(GAMELEVEL_SETTINGS);
    const { data: settingsSubData } = useSubscription(ON_GAMELEVEL_SETTINGS_CHANGED);
    const [setCurrentLevel] = useMutation(SET_CURRENT_LEVEL);
    const [setGameLevelSettings] = useMutation(SET_GAMELEVEL_SETTINGS);
    const [setCurrentLives] = useMutation(SET_CURRENT_LIVES, {
        onError: data => {
            console.log("ERROR!!!")
        }
    });

    if(currentLevelLoading || settingsLoading || currentLivesLoading) return (<p>Loading...</p>);
    if(currentLevelError || settingsError || currentLivesError) return (<p>Error...</p>);

    const currentLevel = currentLevelSubData ? currentLevelSubData.onCurrentLevelChanged : currentLevelData.currentLevel
    const settings = settingsSubData ? settingsSubData.onGameLevelSettingsChanged : settingsData.gameLevelSettings
    const lives = currentLivesSubData ? currentLivesSubData.onCurrentLivesChanged : currentLivesData.currentLives

    const handleResetLevels = () => {
        setCurrentLevel({
            variables: {
                level: 1
            }
        })
    }

    const handleIncrementLevelCount = () => {
        setGameLevelSettings({
            variables: {
                settings: {
                    levels: settings.levels+1,
                    rechargeLiveZones: settings.rechargeLiveZones,
                    saveZones: settings.saveZones
                }
            }
        })
    }

    const handleDecrementLevelCount = () => {
        setGameLevelSettings({
            variables: {
                settings: {
                    levels: settings.levels-1,
                    rechargeLiveZones: settings.rechargeLiveZones,
                    saveZones: settings.saveZones
                }
            }
        })
    }

    const handleResetLives = () => {
        setCurrentLives({
            variables: {
                lives: settings.lives
            }
        })
    }

    const handleIncrementLives = () => {
        setGameLevelSettings({
            variables: {
                settings: {
                    lives: settings.lives+1
                }
            }
        })
    }

    const handleDecrementLives = () => {
        setGameLevelSettings({
            variables: {
                settings: {
                    lives: settings.lives-1
                }
            }
        })
    }

    const handleIncrementCounterMax = () => {
        setGameLevelSettings({
            variables: {
                settings: {
                    counterMax: settings.counterMax+1
                }
            }
        })
    }

    const handleDecrementCounterMax = () => {
        setGameLevelSettings({
            variables: {
                settings: {
                    counterMax: settings.counterMax-1
                }
            }
        })
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent align="center">
                    <List style={{ padding: '0px' }}>
                        <ListItem>
                            <ListItemText primary={"Schritte des Zählers: " + settings.counterMax}/>
                            <ListItemSecondaryAction>
                                <Tooltip title="Schritt entfernen" placement="top">
                                    <Fab disabled={settings.counterMax === 1} onClick={() => handleDecrementCounterMax()} style={{ marginRight: "10px" }} size="small" color="secondary">
                                        <RemoveIcon/>
                                    </Fab>
                                </Tooltip>
                                <Tooltip title="Schritt hinzufügen" placement="top">
                                    <Fab onClick={() => handleIncrementCounterMax()} size="small" color="primary">
                                        <AddIcon/>
                                    </Fab>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <br/>
            <Card>
                <CardContent align="center">
                    <List style={{ padding: '0px' }}>
                        <ListItem>
                            <ListItemText primary={"Level Anzahl: " + settings.levels}/>
                            <ListItemSecondaryAction>
                                <Tooltip title="Level entfernen" placement="top">
                                    <Fab disabled={settings.levels === 1} onClick={() => handleDecrementLevelCount()} style={{ marginRight: "10px" }} size="small" color="secondary">
                                        <RemoveIcon/>
                                    </Fab>
                                </Tooltip>
                                <Tooltip title="Level hinzufügen" placement="top">
                                    <Fab onClick={() => handleIncrementLevelCount()} size="small" color="primary">
                                        <AddIcon/>
                                    </Fab>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemText primary={"Level zurücksetzen"}/>
                            <ListItemSecondaryAction>
                                <Button disabled={currentLevel === 1} onClick={() => handleResetLevels()} variant="contained" color="secondary">
                                    Zurücksetzen
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <br/>
            <Card>
                <CardContent align="center">
                    <List style={{ padding: '0px' }}>
                        <ListItem>
                            <ListItemText primary={"Lebens Anzahl: "+settings.lives}/>
                            <ListItemSecondaryAction>
                                <Tooltip title="Leben entfernen" placement="top">
                                    <Fab disabled={settings.lives === 1} onClick={() => handleDecrementLives()} style={{ marginRight: "10px" }} size="small" color="secondary">
                                        <RemoveIcon/>
                                    </Fab>
                                </Tooltip>
                                <Tooltip title="Leben hinzufügen" placement="top">
                                    <Fab onClick={() => handleIncrementLives()} size="small" color="primary">
                                        <AddIcon/>
                                    </Fab>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider/>
                        <ListItem>
                            <ListItemText primary={"Leben zurücksetzen"}/>
                            <ListItemSecondaryAction>
                                <Button disabled={settings.lives === lives} onClick={() => handleResetLives()} variant="contained" color="secondary">
                                    Zurücksetzen
                                </Button>
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default Settings;
