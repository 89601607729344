import { gql } from '@apollo/client';

export const CURRENT_WHEEL_RESULT = gql`
  query {
    currentWheelResult {
        _id
        position
        name
    }
  }
`;
