import React from 'react';

import FullpageLoader from './FullpageLoader';

import { useQuery } from '@apollo/client';
import { ME } from '../apollo/queries/me';
import { getToken, setToken } from '../tokenProvider';

const AuthLoader = (props) => {

  let url = new URL(window.location.href)
  let params = new URLSearchParams(url.search);

  const newToken = params.get("c");
  if(typeof newToken === "string" && newToken !== localStorage.getItem("joinCode"))
    setToken()

  if(!getToken()) {
    setToken()
  }

  const { data: meData, error: meError, loading: meLoading } = useQuery(ME, { errorPolicy: 'all' })

  if(meLoading || props.error) return <FullpageLoader/>
  if(meError || props.error) return <FullpageLoader/>
  if(meData) return props.children;
}

export default AuthLoader;
