import { gql } from '@apollo/client';

export const BUZZER_SETTINGS_QUERY = gql`
  query {
    buzzerSettings {
      reEnable
      reEnableMs
    }
  }
`;
