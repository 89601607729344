import React, { useState, useEffect, useCallback } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

import SaveIcon from '@material-ui/icons/Save';

import './style.css';

import { listenOnObject, pipe } from 'image-pointer';

import { useMutation } from '@apollo/client';

import { CREATE_IMAGE_POINTER } from '../../apollo/mutations/createImagePointer';

const MarkerPage = (props) => {

  const [ imageUrl, setImageUrl ] = useState("");
  const [ headline, setHeadline ] = useState("");
  const [ x, setX ] = useState(0);
  const [ y, setY ] = useState(0);

  useEffect(() => {
    const source_local = listenOnObject('source', {disableInactive: true, interval: 16});
    source_local.onMove(pipe('source', {customMarker: createMarker("Zielmarker", "#000000"), markerAlign: 'center'}))

    source_local.onMove((x,y,active) => {
      setX(x/1000);
      setY(y/1000);
    });
    return () => [source_local].forEach(x => x.dispose());
  }, [])

  const createMarker = useCallback((name, color) => {
    const marker = document.createElement('span');
    const text = document.createElement('div');
    text.innerText = name;
    const dot = document.createElement('div');
    dot.style.backgroundColor = color;
    marker.append(text, dot);
    return marker;
  });

  const [createImagePointer] = useMutation(CREATE_IMAGE_POINTER, {
    variables: {
      data: {
        headline: headline,
        src: imageUrl,
        x: x,
        y: y
      }
    },
    onCompleted: data => {
      props.handleToggleMarkerOpen()
    }
  });

  return (
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom>
        <b>Marker konfigurieren</b>
      </Typography>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField fullWidth label="Bild URL" variant="outlined" value={imageUrl} onChange={(event) => setImageUrl(event.target.value)}/>
            </Grid>
            <Grid item xs={5}>
              <TextField fullWidth label="Überschrift" variant="outlined" value={headline} onChange={(event) => setHeadline(event.target.value)}/>
            </Grid>
            <Grid align="right" item>
              <Fab onClick={() => createImagePointer()} disabled={!imageUrl || !headline || !x || !y} size="large" color="primary"><SaveIcon/></Fab>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card style={{ marginTop: '12px', padding: '0px' }}>
        <CardContent style={{ padding: '0px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <img width="100%" style={{ display: imageUrl ? "block" : "none" }} src={imageUrl} alt={headline} id="source"/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default MarkerPage;
