import { gql } from '@apollo/client';

export const ON_ACTIVE_IMAGE_POINTER_UPDATE = gql`
  subscription {
    onActiveImagePointerUpdate {
      _id
      headline
      src
      x
      y
    }
  }
`;
