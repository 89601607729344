import React, { Component } from 'react';
import {Route, Switch} from 'react-router-dom';

import Navigation from '../Navigation';
import SDS from '../SDS';
import GDZ from '../GDZ';
import Overview from '../Overview';
import NotFound from '../NotFound';

class PanelContent extends Component {

constructor(props) {
  super(props);
  this.state = {
    items: []
  }

  this.items = [
    {
      key: 'overview',
      path: '/',
      component: Overview,
      name: 'Übersicht',
      disabled: false
    }, {
      key: 'sds',
      path: '/sds',
      component: SDS,
      name: 'SDS',
      disabled: false
    }, {
      key: 'gdz',
      path: '/gdz',
      component: GDZ,
      name: 'GDZ',
      disabled: false
    }
  ];
}

render () {

  const renderItems = () =>
  this.items
  .map(item => (
    <Route path={item.path} exact component={item.component} item={item}/>
  ));

  return (
    <div>
      <div>
        <Navigation items={renderItems()}/>
      </div>
      <div className="content">
        <Switch>
          {renderItems()}
          <Route path='*' exact={true} component={NotFound} />
        </Switch>
      </div>
      <div style={{clear: 'both'}}/>
    </div>
  )
}

}

export default PanelContent;
