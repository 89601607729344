import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import './style.css';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { COUNTER } from '../../apollo/queries/counter';
import { GAMELEVEL_SETTINGS } from '../../apollo/queries/gameLevelSettings';
import { SET_COUNTER } from '../../apollo/mutations/setCounter';
import { SET_GAMELEVEL_SETTINGS } from '../../apollo/mutations/setGameLevelSettings';
import { ON_COUNTER_CHANGED } from '../../apollo/subscriptions/onCounterChanged';
import { ON_GAMELEVEL_SETTINGS_CHANGED } from '../../apollo/subscriptions/onGameLevelSettingsChanged';

const Counter = () => {

  const { data: counterData, error: counterError, loading: counterLoading } = useQuery(COUNTER);
  const { data: counterSubData } = useSubscription(ON_COUNTER_CHANGED);
  const { data: settingsData, error: settingsError, loading: settingsLoading } = useQuery(GAMELEVEL_SETTINGS);
  const { data: settingsSubData } = useSubscription(ON_GAMELEVEL_SETTINGS_CHANGED);
  const [setCounter] = useMutation(SET_COUNTER);

  if(counterLoading || settingsLoading) return (<p>Loading...</p>);
  if(counterError || settingsError) return (<p>Error...</p>);

  const counter = counterSubData ? counterSubData.onCounterChanged : counterData.counter
  const settings = settingsSubData ? settingsSubData.onGameLevelSettingsChanged : settingsData.gameLevelSettings

  const handleIncrementCounter = () => {
    setCounter({
      variables: {
        counter: counter+1
      }
    })
  }

  const handleDecrementCounter = () => {
    setCounter({
      variables: {
        counter: counter-1
      }
    })
  }

  const handleResetCounter = () => {
    setCounter({
      variables: {
        counter: 0
      }
    })
  }

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent align="center">
          <Typography variant="h4" gutterBottom>
            <b>{counter} von {settings.counterMax}</b>
          </Typography>
          <br/>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Button disabled={counter === 0} onClick={() => handleDecrementCounter()} fullWidth variant="contained" color="secondary">
                <RemoveIcon/>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button disabled={counter === settings.counterMax} onClick={() => handleIncrementCounter()} fullWidth variant="contained" color="primary">
                <AddIcon/>
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button fullWidth disabled={counter === 0} onClick={() => handleResetCounter()} variant="contained" color="secondary">
                Zurücksetzen
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Counter;
