import { gql } from '@apollo/client';

export const ON_WHEEL_ENTRIES_CHANGED = gql`
  subscription {
    onWheelEntriesChanged {
        _id
        position
        name
    }
  }
`;
