import React from 'react';

import Grid from '@material-ui/core/Grid';

import './style.css';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {useMutation, useQuery, useSubscription} from "@apollo/client";
import {SET_CURRENT_LEVEL} from "../../apollo/mutations/setCurrentLevel";
import {CURRENT_LEVEL} from "../../apollo/queries/currentLevel";
import {ON_CURRENT_LEVEL_CHANGED} from "../../apollo/subscriptions/onCurrentLevelChanged";
import {GAMELEVEL_SETTINGS} from "../../apollo/queries/gameLevelSettings";
import {ON_GAMELEVEL_SETTINGS_CHANGED} from "../../apollo/subscriptions/onGameLevelSettingsChanged";
import {SET_CURRENT_LIVES} from "../../apollo/mutations/setCurrentLives";
import {CURRENT_LIVES} from "../../apollo/queries/currentLives";
import {ON_CURRENT_LIVES_CHANGED} from "../../apollo/subscriptions/onCurrentLivesChanged";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {SET_JOKER_ENABLED} from "../../apollo/mutations/setJokerEnabled";
import {JOKER_ENABLED} from "../../apollo/queries/jokerEnabled";
import {ON_JOKER_ENABLED_CHANGED} from "../../apollo/subscriptions/onJokerEnabledChanged";

const Settings = () => {

    const { data: currentLivesData, error: currentLivesError, loading: currentLivesLoading } = useQuery(CURRENT_LIVES);
    const { data: currentLivesSubData } = useSubscription(ON_CURRENT_LIVES_CHANGED);
    const { data: currentLevelData, error: currentLevelError, loading: currentLevelLoading } = useQuery(CURRENT_LEVEL);
    const { data: currentLevelSubData } = useSubscription(ON_CURRENT_LEVEL_CHANGED);
    const { data: settingsData, error: settingsError, loading: settingsLoading } = useQuery(GAMELEVEL_SETTINGS);
    const { data: settingsSubData } = useSubscription(ON_GAMELEVEL_SETTINGS_CHANGED);
    const { data: jokerEnabledData, error: jokerEnabledError, loading: jokerEnabledLoading } = useQuery(JOKER_ENABLED);
    const { data: jokerEnabledSubData } = useSubscription(ON_JOKER_ENABLED_CHANGED);
    const [setCurrentLevel] = useMutation(SET_CURRENT_LEVEL);
    const [setCurrentLives] = useMutation(SET_CURRENT_LIVES, {
        onError: data => {
            console.log("ERROR!!!")
        }
    });
    const [setJokerEnabled] = useMutation(SET_JOKER_ENABLED);

    if(currentLevelLoading || settingsLoading || jokerEnabledLoading || currentLivesLoading) return (<p>Loading...</p>);
    if(currentLevelError || settingsError || jokerEnabledError || currentLivesError) return (<p>Error...</p>);

    const currentLevel = currentLevelSubData ? currentLevelSubData.onCurrentLevelChanged : currentLevelData.currentLevel
    const settings = settingsSubData ? settingsSubData.onGameLevelSettingsChanged : settingsData.gameLevelSettings
    const lives = currentLivesSubData ? currentLivesSubData.onCurrentLivesChanged : currentLivesData.currentLives
    const joker = jokerEnabledSubData ? jokerEnabledSubData.onJokerEnabledChanged : jokerEnabledData.jokerEnabled


    const handleWinLevel = () => {
        setCurrentLevel({
            variables: {
                level: currentLevel+1
            }
        })
    }

    const handleSetJoker = (value) => {
        setJokerEnabled({
            variables: {
                enabled: value
            }
        })
    }

    const handleIncrementCurrentLives = () => {
        setCurrentLives({
            variables: {
                lives: lives+1
            }
        })
    }

    const handleDecrementCurrentLives = () => {
        setCurrentLives({
            variables: {
                lives: lives-1
            }
        })
    }

    function getClosestValue(myArray, myValue){
        var i = 0;
        while(myArray[++i] < myValue);
        return myArray[--i];
    }

    const handleLoseLevel = () => {
        console.log(settings.saveZones)
        if(lives <= 1){
            if(currentLevel < getClosestValue(settings.saveZones.sort((a, b) => a - b), currentLevel)) {
                setCurrentLevel({
                    variables: {
                        level: 1
                    }
                })
            } else {
                setCurrentLevel({
                    variables: {
                        level: getClosestValue(settings.saveZones.sort((a, b) => a - b), currentLevel)
                    }
                })
            }
        }
        setCurrentLives({
            variables: {
                lives: lives-1
            }
        })
    }

    return (
        <Grid item xs={12}>
            <Card>
                <CardContent align="center">
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <ButtonGroup
                                fullWidth
                                style={{ marginRight: "15px" }}
                                orientation="vertical"
                            >
                                <Button disabled={lives === 0} onClick={() => handleDecrementCurrentLives()} variant="contained" color="secondary">Leben abziehen</Button>
                                <Button disabled={settings.lives === lives} onClick={() => handleIncrementCurrentLives()}>Leben hinzufügen</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <ButtonGroup
                                fullWidth
                                orientation="vertical"
                            >
                                <Button disabled={!joker} onClick={() => handleSetJoker(false)} variant="contained" color="secondary">Joker nutzen</Button>
                                <Button disabled={joker} onClick={() => handleSetJoker(true)}>Joker freischalten</Button>
                            </ButtonGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <ButtonGroup
                                fullWidth
                                orientation="vertical"
                            >
                                <Button disabled={currentLevel === settings.levels} onClick={() => handleWinLevel()} variant="contained" color="primary">Level bestanden</Button>
                                <Button disabled={lives === 0} onClick={() => handleLoseLevel()}>Level nicht bestanden</Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default Settings;
