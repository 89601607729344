import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import './style.css';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { ALL_PLAYERS } from '../../apollo/queries/allPlayers';
import { ON_PLAYERS_CHANGED } from '../../apollo/subscriptions/onPlayersChanged';
import { CURRENT_ROUND } from '../../apollo/queries/currentRound';
import { ON_ROUND_CHANGED } from '../../apollo/subscriptions/onRoundChanged';
import { SET_ROUND } from '../../apollo/mutations/setRound';
import { SET_ROUND_WINNER } from '../../apollo/mutations/setRoundWinner';
import { RESET_ROUND_WINNERS } from '../../apollo/mutations/resetRoundWinners';

const Scoreboard = () => {

  const [sure, setSure] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedRound, setSelectedRound] = React.useState(1);

  const { data: allPlayersData, error: allPlayersError, loading: allPlayersLoading } = useQuery(ALL_PLAYERS);
  const { data: allPlayersSubData } = useSubscription(ON_PLAYERS_CHANGED);
  const { data: currentRoundData, error: currentRoundError, loading: currentRoundLoading } = useQuery(CURRENT_ROUND);
  const { data: currentRoundSubData } = useSubscription(ON_ROUND_CHANGED);
  const [setRound] = useMutation(SET_ROUND);
  const [setRoundWinner] = useMutation(SET_ROUND_WINNER);
  const [resetRoundWinners] = useMutation(RESET_ROUND_WINNERS);

  if(allPlayersLoading || currentRoundLoading) return (<p>Loading...</p>);
  if(allPlayersError || currentRoundError) return (<p>Error...</p>);

  const allPlayers = allPlayersSubData ? allPlayersSubData.onPlayersChanged : allPlayersData.allPlayers
  const currentRound = currentRoundSubData ? currentRoundSubData.onRoundChanged : currentRoundData.currentRound

  function renderPointsGrid() {
    var rounds = [];
    for (var i = 1; i <= 10; i++) {
      const userData = allPlayers.find(e => e.winnerRounds.find(d => d === i))
      if(userData) {
        rounds.push(
          <div
            key={i}
            onClick={handleOpenMenu}
            style={{ backgroundColor: userData.color, boxShadow: currentRound === i ? "0px 0px 5px 3px white" : "none" }}
            className="box intern"
          >
              <b>{i}</b>
              <span className="miniName">{userData.name}</span>
          </div>
        );
      } else {
        rounds.push(
          <div
            key={i}
            onClick={handleOpenMenu}
            style={{ boxShadow: currentRound === i ? "0px 0px 5px 3px white" : "none" }}
            className="box intern"
          >
            <b>{i}</b>
          </div>
        );
      }
    }
    return (
      <div class="points-wrapper">
        {rounds.map(round =>
          <div onClick={() => setSelectedRound(round.key)}>
            {round}
          </div>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          {allPlayers.map(player =>
            <MenuItem onClick={() => setWinnerOfSpecificRound(player._id, selectedRound)}>{player.name}</MenuItem>
          )}
        </Menu>
      </div>
    );
  }

  const currentRoundWinner = allPlayers.find(e => e.winnerRounds.find(d => d === currentRound))
  const renderWinnerButtons = allPlayers.map(attendee =>
    <Grid item xs={6}>
      <Button
        disabled={currentRoundWinner && currentRoundWinner._id === attendee._id}
        onClick={() => {
            setRoundWinner({
              variables: {
                _id: attendee._id,
                round:currentRound
              }
            })
            if(currentRound < 10) {
              setRound({
                variables: {
                  round: currentRound+1
                }
              })
            }
          }
        }
        fullWidth
        variant="contained"
        color="primary"
      >
        {attendee.name}
      </Button>
    </Grid>
  )

  const increaseRound = () => {
    setRound({
      variables: {
        round: currentRound+1
      }
    })
  }

  const decreaseRound = () => {
    setRound({
      variables: {
        round: currentRound-1
      }
    })
  }

  const resetScoreboard = () => {
    setRound({
      variables: {
        round: 1
      }
    })
    resetRoundWinners()
    setSure(false)
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleCloseMenu() {
    setAnchorEl(null);
  };

  const setWinnerOfSpecificRound = (player, round) => {
    setRoundWinner({
      variables: {
        _id: player,
        round: parseInt(round)
      }
    })
    handleCloseMenu()
  }

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        <b>Punktestand</b>
      </Typography>
      <Card>
        <CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
          <List>
            <ListItem>
              <ListItemText primary={<b>Runde {currentRound}</b>}/>
              <ListItemSecondaryAction>
                <Tooltip title="Runde abziehen" placement="top">
                  <Fab disabled={currentRound === 1} onClick={decreaseRound} style={{ marginRight: "10px" }} size="small" color="secondary">
                    <RemoveIcon/>
                  </Fab>
                </Tooltip>
                <Tooltip title="Runde erhöhen" placement="top">
                  <Fab disabled={currentRound === 10} onClick={increaseRound} size="small" color="primary">
                    <AddIcon/>
                  </Fab>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary={<b>{sure ? "Bist du dir sicher?" : "Punktestand zurücksetzen"}</b>}/>
              <ListItemSecondaryAction>
                {sure ?
                  <div>
                    <Button style={{ marginRight: "12px" }} onClick={resetScoreboard} variant="contained" color="secondary">Ja :o</Button>
                    <Button onClick={() => setSure(false)} variant="contained" color="primary">Nein :x</Button>
                  </div>
                  :
                  <Button onClick={() => setSure(true)} variant="contained" color="secondary">Zurücksetzen</Button>
                }
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <Grid container spacing={3}>
                {renderWinnerButtons}
              </Grid>
            </ListItem>
          </List>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "12px" }}>
        <CardContent align="center">
          {renderPointsGrid()}
        </CardContent>
      </Card>
    </div>
  )
}

export default Scoreboard;
