import { gql } from '@apollo/client';

export const SET_POINTER = gql`
  mutation setPointer($update: ImagePointerDataInput!) {
    setPointer(update: $update) {
      user {
        _id
        color
        name
        joinCode
        winnerRounds
        points
      }
      pointer {
        x
        y
        active
      }
    }
  }
`;

export const SET_POINTER_PLAYERVIEW = gql`
  mutation setPointer($update: ImagePointerDataInput!) {
    setPointer(update: $update) {
      user {
        _id
        color
        name
        winnerRounds
        points
      }
      pointer {
        x
        y
        active
      }
    }
  }
`;
