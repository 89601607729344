import React from 'react';

import './style.css';

import CamBorder from "./CamBorder";
import Scoreboard from "./Scoreboard";
import Marker from "./Marker";

import { useQuery, useSubscription } from '@apollo/client';

import { ALL_PLAYERS } from '../../../apollo/queries/allPlayers';
import { ON_PLAYERS_CHANGED } from '../../../apollo/subscriptions/onPlayersChanged';

const SDSOverlay = () => {

  let url = new URL(window.location.href)
  let params = new URLSearchParams(url.search);

  const { data: allPlayersData, error: allPlayersError, loading: allPlayersLoading } = useQuery(ALL_PLAYERS);
  const { data: allPlayersSubData } = useSubscription(ON_PLAYERS_CHANGED);

  if(allPlayersLoading) return (<p>Loading...</p>);
  if(allPlayersError) return (<p>Error...</p>);

  const allPlayers = allPlayersSubData ? allPlayersSubData.onPlayersChanged : allPlayersData.allPlayers

  function getContent() {
    switch(params.get("content")) {
      case "first_attendee":
        return <CamBorder attendee={allPlayers[0]}/>;
      case "second_attendee":
        return <CamBorder attendee={allPlayers[1]}/>;
      case "scoreboard":
        return <Scoreboard/>;
      case "marker":
        return <Marker/>;
      default:
        return "NO CONTENT";
    }
  }

  return (
    getContent()
  );
}

export default SDSOverlay;
