import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';

import AcUnitIcon from '@material-ui/icons/AcUnit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EditIcon from '@material-ui/icons/Edit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import './style.css';

import { pipe } from 'image-pointer';

import { useSubscription, useQuery, useMutation } from '@apollo/client';

import { ACTIVE_IMAGE_POINTER } from '../../apollo/queries/activeImagePointer';
import { ON_ACTIVE_IMAGE_POINTER_UPDATE } from '../../apollo/subscriptions/onActiveImagePointerUpdate';
import { USER_POINTERS } from '../../apollo/queries/userPointers';
import { ON_POINTER_UPDATE } from '../../apollo/subscriptions/onPointerUpdate';
import { IS_FROZEN } from '../../apollo/queries/isFrozen';
import { IS_TARGET_PUBLIC } from '../../apollo/queries/isTargetPublic';
import { FREEZE_POINTERS } from '../../apollo/mutations/freezePointers';
import { SET_TARGET_PUBLIC } from '../../apollo/mutations/setTargetPublic';
import { ON_FREEZE_UPDATED } from '../../apollo/subscriptions/onFreezeUpdated';
import { ON_TARGET_PUBLIC_UPDATED } from '../../apollo/subscriptions/onTargetPublicUpdated';
import { UPDATE_ACTIVE_IMAGE_POINTER } from '../../apollo/mutations/updateActiveImagePointer';

const createMarker = (name, color) => {
  const marker = document.createElement('span');
  const text = document.createElement('div');
  text.innerText = name;
  const dot = document.createElement('div');
  dot.style.backgroundColor = color;
  marker.append(text, dot);
  return marker;
}

let userPointerCache = {};
const getUserPointer = (user) => {
  if (userPointerCache[user._id]) return userPointerCache[user._id];
  return userPointerCache[user._id] = pipe('target', {customMarker: createMarker(user.name, user.color)});
}

let disposed = false;

const disposeAllPointers = () => {
  disposed = true;
  for (const key of Object.keys(userPointerCache))
    userPointerCache[key].dispose()
  userPointerCache = {};
}

const MarkerEvaluationImage = (props) => {

  useEffect(() => () => disposeAllPointers(), [])

  useEffect(() => {
    disposed = false;
    if(props.activeImagePointer && props.activeImagePointer.x && props.activeImagePointer.y) {
      const x = props.activeImagePointer.x*1000;
      const y = props.activeImagePointer.y*1000;
      let targetPointer = {
        _id: "targetMarker",
        name: "Zielmarker",
        color: "#000000"
      }
      const pointer = getUserPointer(targetPointer);
      pointer(x, y, true);
    }
  }, [])

  return (
    <img width="100%" src={props.activeImagePointer ? props.activeImagePointer.src : ""} alt={props.activeImagePointer ? props.activeImagePointer.headline : ""} id="target"/>
  )
}

const MarkerEvaluation = () => {

  const { data: activeImagePointerData, error: activeImagePointerError, loading: activeImagePointerLoading } = useQuery(ACTIVE_IMAGE_POINTER);
  const { data: activeImagePointerSubData } = useSubscription(ON_ACTIVE_IMAGE_POINTER_UPDATE);
  const { data: userPointerData, error: userPointerError, loading: userPointerLoading } = useQuery(USER_POINTERS);
  const { data: isFrozenData, error: isFrozenError, loading: isFrozenLoading } = useQuery(IS_FROZEN);
  const { data: isFrozenSubData } = useSubscription(ON_FREEZE_UPDATED);
  const { data: isTargetPublicData, error: isTargetPublicError, loading: isTargetPublicLoading } = useQuery(IS_TARGET_PUBLIC);
  const { data: isTargetPublicSubData } = useSubscription(ON_TARGET_PUBLIC_UPDATED);

  const [updateActiveImagePointer] = useMutation(UPDATE_ACTIVE_IMAGE_POINTER);
  const [freezePointers] = useMutation(FREEZE_POINTERS);
  const [setTargetPublic] = useMutation(SET_TARGET_PUBLIC);

  const { data: userPointerSubData } = useSubscription(ON_POINTER_UPDATE, {onSubscriptionData: raw => {
    if (disposed) return;
    const data = raw.subscriptionData.data.onPointerUpdate;
    const pointer = getUserPointer(data.user);
    const {x, y, active} = data.pointer;
    pointer(x, y, active);
  }});

  if(userPointerLoading || activeImagePointerLoading || isTargetPublicLoading || isFrozenLoading) return (<p>Loading...</p>);
  if(userPointerError || activeImagePointerError || isTargetPublicError || isFrozenError) return (<p>Error...</p>);

  const activeImagePointer = activeImagePointerSubData ? activeImagePointerSubData.onActiveImagePointerUpdate : activeImagePointerData.activeImagePointer
  const userPointer = userPointerSubData ? userPointerSubData.onPointerUpdate : userPointerData.userPointers
  const isFrozen = isFrozenSubData ? isFrozenSubData.onFreezeUpdated : isFrozenData.isFrozen;
  const isTargetPublic = isTargetPublicSubData ? isTargetPublicSubData.onTargetPublicUpdated : isTargetPublicData.isTargetPublic;

  const handleCloseEvaluation = () => {
    updateActiveImagePointer({
      variables: {
        _id: null
      }
    })
    freezePointers({
      variables: {
        freeze: false
      }
    })
    setTargetPublic({
      variables: {
        public: false
      }
    })
  }

  return (
    <Grid item xs={12}>
      <Button startIcon={<ArrowBackIcon/>} onClick={() => handleCloseEvaluation()} color="primary" variant="contained">Zurück</Button>
      {
        isFrozen ?
        <Button style={{ marginLeft: '12px' }} startIcon={<EditIcon/>} onClick={() => freezePointers({ variables: { freeze: false }})} color="secondary" variant="contained">Marker auftauen</Button>
        :
        <Button style={{ marginLeft: '12px' }} startIcon={<AcUnitIcon/>} onClick={() => freezePointers({ variables: { freeze: true }})} color="primary" variant="contained">Marker einfrieren</Button>
      }
      {
        isTargetPublic ?
        <Button style={{ marginLeft: '12px' }} onClick={() => setTargetPublic({ variables: { public: false }})} startIcon={<VisibilityOffIcon/>} color="secondary" variant="contained">Marker ausblenden</Button>
        :
        <Button style={{ marginLeft: '12px' }} onClick={() => setTargetPublic({ variables: { public: true }})} startIcon={<VisibilityIcon/>} color="primary" variant="contained">Marker im Overlay anzeigen</Button>
      }
      <Card style={{ marginTop: '12px', padding: '0px' }}>
        <CardContent style={{ padding: '0px' }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MarkerEvaluationImage userPointers={userPointer && userPointer} activeImagePointer={activeImagePointer && activeImagePointer} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default MarkerEvaluation;
