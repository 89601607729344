import { gql } from '@apollo/client';

export const ON_BUZZ = gql`
  subscription {
    onBuzz {
      _id
      color
      name
      joinCode
      winnerRounds
      points
    }
  }
`;

export const ON_BUZZ_PLAYERVIEW = gql`
  subscription {
    onBuzz {
      _id
      color
      name
      winnerRounds
      points
    }
  }
`;
