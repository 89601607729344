import { gql } from '@apollo/client';

export const USER_POINTERS = gql`
  query {
    userPointers {
      user {
        _id
        name
        color
        joinCode
        winnerRounds
        points
      }
      pointer {
        x
        y
        active
      }
    }
  }
`;
