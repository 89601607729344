import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Heart from '../../resources/heart.svg';

import './style.css';

import { useQuery, useMutation, useSubscription } from '@apollo/client';

import { CURRENT_LEVEL } from '../../apollo/queries/currentLevel';
import { GAMELEVEL_SETTINGS } from '../../apollo/queries/gameLevelSettings';
import { SET_GAMELEVEL_SETTINGS } from '../../apollo/mutations/setGameLevelSettings';
import { ON_CURRENT_LEVEL_CHANGED } from '../../apollo/subscriptions/onCurrentLevelChanged';
import { ON_GAMELEVEL_SETTINGS_CHANGED } from '../../apollo/subscriptions/onGameLevelSettingsChanged';
import {FormControl, InputAdornment, OutlinedInput} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import Fab from "@material-ui/core/Fab";

const Level = () => {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedLevel, setSelectedLevel] = React.useState(0);
  const [levelName, setLevelName] = React.useState("");

  const { data: currentLevelData, error: currentLevelError, loading: currentLevelLoading } = useQuery(CURRENT_LEVEL);
  const { data: currentLevelSubData } = useSubscription(ON_CURRENT_LEVEL_CHANGED);
  const { data: settingsData, error: settingsError, loading: settingsLoading } = useQuery(GAMELEVEL_SETTINGS);
  const { data: settingsSubData } = useSubscription(ON_GAMELEVEL_SETTINGS_CHANGED);

  const [setGameLevelSettings] = useMutation(SET_GAMELEVEL_SETTINGS);

  if(currentLevelLoading || settingsLoading) return (<p>Loading...</p>);
  if(currentLevelError || settingsError) return (<p>Error...</p>);

  const currentLevel = currentLevelSubData ? currentLevelSubData.onCurrentLevelChanged : currentLevelData.currentLevel
  const settings = settingsSubData ? settingsSubData.onGameLevelSettingsChanged : settingsData.gameLevelSettings

  Array.prototype.remove = function() {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
      what = a[--L];
      while ((ax = this.indexOf(what)) !== -1) {
        this.splice(ax, 1);
      }
    }
    return this;
  };

  const handleSetLevelType = (level, type) => {
    if(type === "setNoobLevel") {
      let rechargeLiveZones = []
      rechargeLiveZones.push(parseInt(level)+1)
      setGameLevelSettings({
        variables: {
          settings: {
            rechargeLiveZones: rechargeLiveZones
          }
        }
      })
    } else if(type === "setCheckpoint") {
      let saveZones = settings.saveZones
      saveZones.push(parseInt(level))
      setGameLevelSettings({
        variables: {
          settings: {
            saveZones: saveZones
          }
        }
      })
    } else if(type === "removeCheckpoint") {
      let saveZones = settings.saveZones
      saveZones.remove(parseInt(level));
      setGameLevelSettings({
        variables: {
          settings: {
            saveZones: saveZones
          }
        }
      })
    } else if(type === "setRechargeLiveZone") {
      let rechargeLiveZones = settings.rechargeLiveZones
      rechargeLiveZones.push(parseInt(level));
      setGameLevelSettings({
        variables: {
          settings: {
            rechargeLiveZones: rechargeLiveZones
          }
        }
      })
    } else if(type === "removeRechargeLiveZone") {
      let rechargeLiveZones = settings.rechargeLiveZones
      rechargeLiveZones.remove(parseInt(level));
      setGameLevelSettings({
        variables: {
          settings: {
            rechargeLiveZones: rechargeLiveZones
          }
        }
      })
    }
    handleCloseMenu()
  }

  const handleSetLevelName = (level, name) => {
    let levelNamesArray = settings.levelNames
    levelNamesArray.splice(level-1, 1, name);
    setGameLevelSettings({
      variables: {
        settings: {
          levelNames: levelNamesArray
        }
      }
    }).then(() => {
      handleCloseMenu()
    })
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleCloseMenu() {
    setAnchorEl(null);
    setLevelName("")
  };

  function renderLevelGrid() {
    var levelMap = [];
    for (var i = 1; i <= settings.levels; i++) {
      levelMap.push(
        <div
          onClick={handleOpenMenu}
          className="level-container-panel"
          key={i}
        >
          {settings.rechargeLiveZones.includes(i) &&
          <img alt="GDZ Heart" className="heart-panel" src={Heart}/>}
          {i < settings.rechargeLiveZones.sort((a, b) => a - b)[0] ?
            <div style={{ boxShadow: currentLevel === i && "0px 0px 5px 3px white" }} className={(settings.levels === i && "level-box-end") || (settings.saveZones.includes(i) ? "level-box-noob checkpoint" : "level-box-noob")}>
              <span className="level-count">{i}</span>
                <span className="miniName">{settings.levelNames[i-1]}</span>
            </div>
            :
            <div style={{ boxShadow: currentLevel === i && "0px 0px 5px 3px white" }} className={(settings.levels === i && "level-box-end") || (settings.saveZones.includes(i) ? "level-box-normal checkpoint" : "level-box-normal")}>
              <span className="level-count">{i}</span>
              <span className="miniName">{settings.levelNames[i-1]}</span>
            </div>
          }
        </div>
      );
    }
    return (
      <div className="level-wrapper-panel">
        {levelMap.map((level) =>
          <div onClick={() => setSelectedLevel(level.key)}>
            {level}
          </div>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <Typography style={{margin:'10px'}}>
            <b>Level {selectedLevel} - {settings.levelNames[selectedLevel-1]}</b>
          </Typography>
          <FormControl onKeyDown={(e) => e.stopPropagation()} variant="outlined" style={{margin:'10px'}}>
            <OutlinedInput
                placeholder={'Titel für Level '+selectedLevel}
                id="outlined-adornment-password"
                value={levelName}
                onChange={event => setLevelName(event.target.value)}
                endAdornment={
                  <InputAdornment style={{ height: '0px' }} position="end">
                    <IconButton
                        onClick={() => setLevelName(null)}
                        edge="end"
                    >
                      <Fab onClick={() => handleSetLevelName(selectedLevel, levelName)} size="small" color="primary" >
                        <CheckIcon/>
                      </Fab>
                    </IconButton>
                  </InputAdornment>
                }
            />
          </FormControl>
          <MenuItem onClick={() => handleSetLevelType(selectedLevel, "setNoobLevel")}>
            Beginner Level Grenze
          </MenuItem>
          {settings.saveZones.includes(parseInt(selectedLevel)) ?
          <MenuItem onClick={() => handleSetLevelType(selectedLevel, "removeCheckpoint")}>
            Checkpoint entfernen
          </MenuItem> :
          <MenuItem onClick={() => handleSetLevelType(selectedLevel, "setCheckpoint")}>
            Checkpoint setzen
          </MenuItem>}
          {settings.rechargeLiveZones.includes(parseInt(selectedLevel)) ?
          <MenuItem disabled={parseInt(selectedLevel) === settings.rechargeLiveZones.sort((a, b) => a - b)[0]} onClick={() => handleSetLevelType(selectedLevel, "removeRechargeLiveZone")}>
            Leben auffüllen entfernen
          </MenuItem> :
          <MenuItem onClick={() => handleSetLevelType(selectedLevel, "setRechargeLiveZone")}>
            Leben auffüllen setzen
          </MenuItem>}
        </Menu>
      </div>
    );
  }

  return (
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom>
        <b>Level</b>
      </Typography>
      <Card>
        <CardContent align="center">
          {renderLevelGrid()}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default Level;
